import React, { ReactNode } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Button,
  ButtonProps,
  Stack,
  StackProps,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MyMenuContent, MyMenuContentTitle } from '@/constant/mymenu';
import Image from 'next/image';
import AngleBracketIcon from '@/public/icons/angle_bracket.svg';
import { Passport } from '@/types/api';
import { imgUrlChk } from '@/utils/function';
import ImageWithErrorHandling from '@/components/ImageWithErrorHandling';

export const MypageContentSx: SxProps<Theme> = {
  '.dot': {
    width: '4px',
    height: '4px',
    backgroundColor: 'white',
    borderRadius: '100%',
  },
  '.profile': {
    marginTop: '12px',
    fontSize: '14px',
    color: '#B7BBC2',
    '.name': { fontWeight: 500, fontSize: '20px', color: 'white' },
    '.nickname': {
      textTransform: 'capitalize',
      fontSize: '16px',
      color: 'white',
    },
  },
  '.jobWrap': {
    margin: '28px 20px 0',
    padding: '12px',
    borderRadius: '4px',
    backgroundColor: '#F9FAFB',
    '.job': {
      width: 'fit-content',
      margin: 0,
      padding: '2px 8px',
      border: '1px solid #888D96',
      borderRadius: '4px',
      backgroundColor: '#F3F5F6',
      fontSize: '12px',
      color: 'black',
      textTransform: 'uppercase',
    },
    '.empty': {
      margin: 0,
      textAlign: 'center',
      letterSpacing: '-0.05px', // 디자인 그대로 적용 시 내용이 넘침
      fontSize: '14px',
      color: '#B7BBC2',
    },
    '.summary': { margin: '4px 0 0', fontSize: '14px' },
    '.content': {
      margin: '8px 0 0',
      wordWrap: 'break-word',
      fontSize: '12px',
      color: '#888D96',
    },
  },
  'button.edit-profile': {
    display: 'block',
    margin: '2px 20px 0 auto',
    color: 'black',
    textDecoration: 'underline',
  },
  section: {
    padding: '24px 20px',
    borderTop: '8px solid #F9FAFB',
    h2: {
      margin: 0,
      padding: '0 4px',
      fontWeight: 400,
      fontSize: '14px',
      color: '#888D96',
    },
  },
};

export const ProfileAccordion = styled(
  ({
    defaultPassportId = '',
    profileList = [],
    children,
    ...props
  }: AccordionProps & {
    defaultPassportId?: string;
    profileList?: Passport[];
  }) => (
    <Accordion disableGutters square {...props}>
      <AccordionSummary
        expandIcon={<AngleBracketIcon width={'24px'} height={'24px'} />}
        aria-controls="profile-panel"
        id="profile-panel"
      >
        <Typography component="span">프로필 전환</Typography>
        <Stack direction={'row'} className={'preview-profile'}>
          {[...profileList]
            ?.sort((a, b) => {
              if (a.passportId === defaultPassportId) return -1;
              if (b.passportId === defaultPassportId) return 1;
              return 0; // 원래 순서 유지
            })
            .slice(0, 3)
            ?.map((item, index) => (
              <ImageWithErrorHandling
                key={index}
                src={imgUrlChk(item.detail.profileImageUrl)}
                width={32}
                height={32}
                alt={`${item.detail.name} Profile Image}`}
              />
            ))}
          {profileList && profileList.length > 3 && (
            <span>+{profileList?.length - 2}</span>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  ),
)(() => ({
  '&.MuiAccordion-root': {
    border: 'none',
    boxShadow: 'none',
    '&::before': { display: 'none' },
  },
  '.MuiAccordion-gutters': {
    backgroundColor: 'red',
  },
  '.MuiAccordionSummary-root': {
    padding: '16px 20px',
    borderTop: '1px solid #E7E9EC',
    '.MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
      fontSize: '16px',
      '.preview-profile': {
        alignItems: 'center',
        padding: '0 12px',
        img: {
          marginLeft: '-8px',
          borderRadius: '100%',
          border: '1px solid white',
        },
        span: {
          display: 'inline-block',
          width: '32px',
          height: '32px',
          backgroundColor: 'rgba(255, 255,255, 0.8)',
          marginLeft: '-32px',
          fontSize: '12px',
          color: '#5F636B',
          borderRadius: '100%',
          lineHeight: '32px',
          textAlign: 'center',
        },
      },
    },
    '&.Mui-expanded': {
      '.preview-profile': { opacity: 0 },
    },
  },
  '.MuiAccordionDetails-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '24px 16px',
    borderTop: '1px solid #E7E9EC',
  },
}));

export const ProfileItem = styled(
  ({
    profile,
    children,
    sx,
    ...props
  }: StackProps & ButtonProps & { profile: Passport }) => (
    <Stack
      component={'button'}
      direction={'row'}
      alignItems={'center'}
      sx={{
        textAlign: 'left',
        padding: '4px',
        borderRadius: '4px',
        '.profile-image-wrap': {
          width: '38px',
          height: '38px',
          borderRadius: '100%',
          marginRight: '8px',
          padding: '2px',
          'img.profile-image': {
            borderRadius: '100%',
          },
        },
        '.profile-title': {
          flex: 1,
          margin: 0,
          marginRight: '4px',
          fontSize: '14px',
          span: {
            fontSize: '12px',
            color: '#888D96',
            '::before': {
              content: '""',
              display: 'inline-block',
              width: '2px',
              height: '2px',
              margin: '0 4px',
              backgroundColor: '#888D96',
              borderRadius: '100%',
              verticalAlign: 'middle',
            },
          },
        },
        '&.selected': {
          '.profile-image-wrap': {
            border: '1px solid #0066CC',
          },
          '&::after': {
            content: '"현재프로필"',
            padding: '4px 8px',
            backgroundColor: '#F3F5F6',
            borderRadius: '4px',
            fontSize: '12px',
            color: '#0066CC',
          },
        },
        '&:hover': {
          backgroundColor: 'rgba(25, 118, 210, 0.08)',
        },
        ...sx,
      }}
      {...props}
    >
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        className={'profile-image-wrap'}
      >
        <ImageWithErrorHandling
          src={imgUrlChk(profile.detail.profileImageUrl)}
          width={32}
          height={32}
          alt={'profile-image'}
          className={'profile-image'}
        />
      </Stack>
      <p className={'profile-title ellipsis'}>
        {profile.detail.companyName}
        <span>{profile.detail.departmentName}</span>
      </p>
    </Stack>
  ),
)(() => ({}));

export type SubMenuButtonProps = {
  icon?: ReactNode;
  menu: MyMenuContent;
} & ButtonProps;
export const SubMenuButton = styled(
  ({ icon, menu, content, sx, ...props }: SubMenuButtonProps) => (
    <Button
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        padding: '4px',
        '.menu-name': {
          flex: 1,
          textAlign: 'left',
          fontSize: '16px',
          color: 'black',
        },
        '.menu-content': {
          fontSize: '14px',
          color: '#888D96',
        },
        ...sx,
      }}
      {...props}
    >
      <Image
        alt={`${menu}-icon`}
        src={`/icons/mypage/${menu}.svg`}
        width={20}
        height={20}
      />
      <Typography className={'menu-name'}>
        {MyMenuContentTitle[menu]}
      </Typography>
      {content && <Typography className={'menu-content'}>{content}</Typography>}
    </Button>
  ),
)();

export const LogoutButton = styled(Button)(() => ({
  display: 'block',
  width: '100%',
  fontSize: '14px',
  color: 'black',
  padding: '12px 16px',
  border: '1px solid #E7E9EC',
  borderRadius: '8px',
}));
