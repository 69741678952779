import {
  AppConfig,
  ApprovalFilter,
  ApprovalState,
  ApprovalTempFilter,
  MyInfo,
  NotiState,
  OpenState,
  SalesData,
  SocialLayoutState,
  SocialState,
  VersionInfo,
  WebApprovalState,
} from '@/types/api';
import dayjs from 'dayjs';
import { getNowFormat } from '@/utils/format';

export const INIT_MY_INFO: MyInfo = {
  userId: '',
  passportId: '',
  employeeNumber: '',
  name: '',
  nickname: '',
  email: '',
  positionName: '',
  positionCode: '',
  departmentName: '',
  departmentCode: '',
  departmentId: '',
  divisionName: '',
  divisionCode: '',
  companyCode: '',
  companyName: '',
  organizationFullName: '',
  organizationShortName: '',
  profileImageUrl: '',
  phoneInside: '',
  phoneMobile: '',
  birthdayType: 0,
  birthday: '',
  joinedAt: '',
  enterDate: '',
  language: 'default',
  isLeader: false,
  job: {
    name: '',
    summary: '',
    content: '',
  },
  isFnCo: false,
  isFnHolding: false,
};

export const INIT_APPROVAL_STATE: ApprovalState = {
  headerKey: 0,
  myApprovalKey: 0,
  myWriteKey: 0,
  unRead: false,
};

export const INIT_WEB_APPROVAL_STATE: WebApprovalState = {
  open: true,
  keyword: '',
};

export const INIT_WEB_APPROVAL_FILTER_STATE: ApprovalFilter = {
  id: 1,
  sortType: 'authoredAt',
  types: [],
  authorPassportIds: [],
  approverPassportIds: [],
  approverStatuses: [],
  currentApproverPassportIds: [],
  coApproverPassportIds: [],
  coApproverStatuses: [],
  statuses: [],
  referrerPassportIds: [],
  authorDepartmentIds: [],
  approverDepartmentIds: [],
  endAuthoredAt: [],
  unreadPassportIds: [],
};

export const INIT_WEB_APPROVAL_TEMP_FILTER_STATE: ApprovalTempFilter = {
  types: [],
  authorPassportIds: [],
  approverPassportIds: [],
  currentApproverPassportIds: [],
  coApproverPassportIds: [],
};

export const INIT_APP_SETTINGS: AppConfig = {
  alarmNotice: true,
  dayFind: false,
  seasonFind: false,
  server: 'product',
  language: 'ko',
};

export const INIT_SALES_DATA: SalesData = {
  mainDate: dayjs().subtract(1, 'day'),
  mainSeason: 'ALL',
  rivalDate: dayjs().subtract(1, 'day'),
  analysisDate: dayjs().subtract(1, 'day'),
  analysisSeason: 'ALL',
};

export const INIT_VERSION_INFO: VersionInfo = {
  isTestFlight: false,
  isNeeded: false,
  currentVersion: '',
  latestVersion: '',
  storeUrl: '',
};

export const INIT_SALES_OPEN_STATE: OpenState = {
  main: {
    mainIndex: -1,
    subIndex: -1,
  },
  shopCode: '',
};

export const INIT_SOCIAL_STATE: SocialState = {
  categoryData: [],
  isReserve: false,
  isBookmark: false,
  categoryId: '',
  groupId: '',

  currentDate: getNowFormat('yyyy-MM-dd HH:mm:ss'),
};

export const INIT_SOCIAL_LAYOUT_STATE: SocialLayoutState = {
  isRender: false,
  columns: 4,
  isExpanded: true,
  stickyFlexDirection: 'row',
};

export const INIT_NOTI_STATE: NotiState = {
  data: [],
};
