import React from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { SubContentProps } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSettingContent';
import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import {
  IntranetLibraryBody1,
  IntranetLibraryBody2,
} from '@/components/@IntranetLibrary/Typography';
import IntranetLibrarySwitch from '@/components/@IntranetLibrary/Switch';
import useAlarmSettingProps from './useAlarmSettingProps';
import ApprovalAlarmSetting from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/AlarmSetting/Approval';
import { isLoop } from '@babel/types';

const AlarmSettingContent = (props: SubContentProps) => {
  const {
    approvalSummary,
    isJiraActivated,
    jiraServiceName,
    isLoading,
    alarmOnChange,
    approvalMenuOnClick,
    ...approvalAlarmProps
  } = useAlarmSettingProps(props);

  return (
    <>
      <Stack
        divider={<Divider orientation="horizontal" flexItem />}
        sx={{ padding: '0 20px' }}
      >
        <ContentDescription sx={{ margin: '28px 0' }}>
          알림을 켜면 Portal과 E-mail로 알림을 받을 수 있어요.
        </ContentDescription>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            padding: '16px 0 16px 4px',
            cursor: 'pointer',
          }}
          onClick={approvalMenuOnClick}
        >
          <Stack sx={{ flex: 1 }}>
            <IntranetLibraryBody1>APPROVAL</IntranetLibraryBody1>
            <IntranetLibraryBody2>{approvalSummary}</IntranetLibraryBody2>
          </Stack>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_593_99426)">
              <path
                d="M10 7L15 12L10 17"
                stroke="#888D96"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_593_99426">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Box>
        <ContentDescription
          sx={{
            padding: '8px',
            margin: '16px 0',
            display: 'flex',
            flexDirection: 'row',
            gap: '12px',
            alignItems: 'center',
          }}
        >
          <img
            src={'/icons/jira/jira.png'}
            srcSet="/icons/jira/jira.png 1x, /icons/jira/jira@2x.png 2x, /icons/jira/jira@3x.png 3x"
            alt={'jira icon'}
            width={56}
            height={56}
          />
          <Stack>
            <IntranetLibraryBody1>{jiraServiceName}</IntranetLibraryBody1>
            <IntranetLibraryBody2>
              나와 관련된 티켓의 업데이트가 있을 때 알림을 받아요.
            </IntranetLibraryBody2>
          </Stack>
          <IntranetLibrarySwitch
            checked={isJiraActivated}
            onChange={alarmOnChange}
            disabled={isLoading}
            name={'jira'}
          />
        </ContentDescription>
      </Stack>
      <ApprovalAlarmSetting
        {...approvalAlarmProps}
        alarmOnChange={alarmOnChange}
        isLoading={isLoading}
      />
    </>
  );
};

export default AlarmSettingContent;
