import React, { PropsWithChildren } from 'react';
import { Box, Divider, SxProps, Typography } from '@mui/material';

type Props = PropsWithChildren<{ sx?: SxProps }>;

export const IntranetLibraryBody1: React.FC<Props> = ({ children, sx }) => {
  return (
    <Typography
      sx={{
        color: '#000',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const IntranetLibraryBody2: React.FC<Props> = ({ children, sx }) => {
  return (
    <Typography
      sx={{
        color: '#888D96',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const IntranetLibraryCaption: React.FC<Props> = ({ children, sx }) => {
  return (
    <Typography
      sx={{
        color: '#888D96',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
