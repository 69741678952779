import React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { LanguageValue, MyInfo } from '@/types/api';
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup';
import useLanguageSettingContentProps from '@/components/MenuDrawer/MyMenuDrawer/V2/@hooks/useLanguageSettingContentProps';
import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import BlackButton from '@/components/Button/BlackButton';
import { MyMenuDrawerProps } from '@/components/MenuDrawer/MyMenuDrawer/V2';

export type SubContentProps = Pick<MyMenuDrawerProps, 'myInfo' | 'menuOnClose'>;

export type LanguageSettingContentProps = Pick<MyInfo, 'language'> & {
  readonly selectedLanguage: LanguageValue;
  readonly selectOptions: { value: string; label: string }[];
  readonly handleSelect: RadioGroupProps['onChange'];
  readonly handleChange: () => void;
};

const LanguageSettingContent = ({ myInfo }: SubContentProps) => {
  const {
    language,
    selectedLanguage,
    selectOptions,
    handleSelect,
    handleChange,
  } = useLanguageSettingContentProps({ myInfo });

  return (
    <Box padding={'28px 20px'}>
      <ContentDescription>
        언어 변경은 새로고침하여 적용됩니다.
      </ContentDescription>
      <RadioGroup
        defaultValue={language}
        name="language"
        value={selectedLanguage}
        onChange={handleSelect}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          margin: '28px 0 24px',
          padding: '24px 0 24px 3px',
          borderTop: '1px solid #E7E9EC',
          borderBottom: '1px solid #E7E9EC',
          label: { position: 'relative' },
          '[data-default="true"]::after': {
            content: '"사용중"',
            display: 'inline-block',
            position: 'absolute',
            right: 0,
            padding: '4px 12px',
            borderRadius: '999px',
            backgroundColor: 'rgba(0, 102, 204, 0.12)',
            fontSize: '12px',
            color: '#0066CC',
          },
        }}
      >
        {selectOptions.map(({ value, label }) => (
          <FormControlLabel
            key={`${value}`}
            value={value}
            control={<Radio />}
            label={label}
            data-default={value === language}
          />
        ))}
      </RadioGroup>
      <BlackButton
        disabled={selectedLanguage === language}
        onClick={handleChange}
      >
        변경하기
      </BlackButton>
    </Box>
  );
};

export default LanguageSettingContent;
