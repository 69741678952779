export const PageURL = {
  HOME: '/',
  LOGIN: '/login',
  APPROVAL: '/approval',
  SOCIAL: '/social',
  // TODO 변경 필요
  WORK: '/work',
  BOARD_DETAIL: (documentId: string) => `/board/${documentId}`,
  PERSON: '/#person_open',
  COMMUNITY: '/#F&F 공지사항_open',
  NEWS: '/#F&F News_open',
  TREND: '#Trend & issue_open',
  LIFE_HTML: '/html/life.html',
};
