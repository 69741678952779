import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import useCustomToast from '@/hooks/useCustomToast';

const useApiRequest = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleRequest = useCallback(
    async (
      requestHandler: () => Promise<any>,
      handleError?: (data?: any) => void,
    ) => {
      try {
        setIsLoading(true);

        await requestHandler();
      } catch (e) {
        if (e instanceof AxiosError) {
          handleError?.(e.response?.data);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  return {
    request: handleRequest,
    isLoading,
  };
};

export default useApiRequest;

export const useApiRequestWithToast = () => {
  const { isLoading, request } = useApiRequest();
  const { showSuccess, showError } = useCustomToast();

  const handleRequest = useCallback(
    async (
      requestHandler: () => Promise<any>,
      successMessage: string,
      defaultErrorMessage: string,
    ) => {
      await request(
        async () => {
          await requestHandler();
          showSuccess({ message: successMessage });
        },
        (data) => {
          showError({ message: data?.message ?? defaultErrorMessage });
        },
      );
    },
    [],
  );

  return {
    isLoading,
    request: handleRequest,
  };
};
