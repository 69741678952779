import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { readBoard, useGetBoardDetail } from '@/hooks/apiHooks';
import { ModalInner } from '@/components/Board/BoardDetailModal/styles';
import { Box, CircularProgress, Modal } from '@mui/material';
import BoardDetail from 'components/Board/BoardDetail';
import { BoardCommentSection } from '@/types/board';
import useLayoutSize from '@/hooks/useLayoutSize';
import { LayoutType } from '@/constant/layout';
import { ActionType } from '@/constant/button';
import { isNativeApp } from '@/utils/userEnv';
import { PostMessageActionType, sendPostMessage } from '@/utils/postMessage';
import { useCustomDialog } from '@/hooks/useCustomDialog';

export type BoardDetailModalRef = {
  open: (documentId: string) => void;
  close: () => void;
};

export type BoardDetailModalProps = {
  title?: string;
  onChanged?: (action: ActionType, documentId: string) => void;
  onChange?: (id: string, changes: Record<string, any>) => void;
  disabled?: boolean;
};

export const BoardDetailHeaderId = 'board-detail-header';
const modalHeaderId = `modal-${BoardDetailHeaderId}`;

const BoardDetailModal = forwardRef<BoardDetailModalRef, BoardDetailModalProps>(
  (props, ref) => {
    const { title = '', onChanged, onChange, disabled } = props;

    const dialog = useCustomDialog();

    const { isMobile, isSmallDesktop } = useLayoutSize(
      LayoutType.BOARD_DETAIL_MODAL,
    );
    const [documentId, setDocumentId] = useState<string>('');
    const { data, isLoading, mutate, error } = useGetBoardDetail(documentId);
    const [isOpen, setIsOpen] = useState(false);
    const [isRead, setIsRead] = useState(false);
    const [isRowDirection, setIsRowDirection] = useState(false);

    const modalInnerRef = useRef<HTMLDivElement>(null);
    const [modalPadding, setModalPadding] = useState(
      isMobile ? '20px' : '24px',
    );

    useEffect(() => {
      if (isRead && data && data.id === documentId) {
        mutate({ ...data, watchCount: data.watchCount + 1 }, false);
      }
    }, [data?.id, isRead]);

    useEffect(() => {
      setIsRowDirection(
        !isMobile &&
          !isSmallDesktop &&
          data?.config.commentSection === BoardCommentSection.RIGHT,
      );
      setModalPadding(isMobile ? '20px' : '24px');
    }, [data, isMobile, isSmallDesktop]);

    useEffect(() => {
      if (isOpen && error) {
        const e = error as any;
        dialog.show({
          title: e?.response?.data?.message ?? '에러가 발생했습니다',
          onConfirm: () => setIsOpen(false),
        });
      }
    }, [isOpen, error]);

    const close = () => {
      setIsOpen(false);
      setDocumentId('');

      if (isNativeApp()) {
        sendPostMessage(PostMessageActionType.NAVI_OPEN);
      }

      if (data && onChange) {
        // 리스트 Mutate
        onChange(data.id, { isRead: true });
      }
    };

    useImperativeHandle(ref, () => ({
      open: (documentId: string) => {
        setDocumentId(documentId);
        setIsRead(false);
        setIsOpen(true);

        if (isNativeApp()) {
          sendPostMessage(PostMessageActionType.NAVI_CLOSE);
        }

        if (documentId)
          readBoard(documentId, () => {
            setIsRead(true);
          });
      },
      close,
    }));

    return (
      <Modal
        open={isOpen}
        onClose={close}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ '> .MuiBox-root:focus': { outline: 'none' } }}
      >
        <ModalInner
          isMobile={isMobile}
          title={title}
          contentRef={modalInnerRef}
          onClose={close}
          sx={{
            '--modal-padding': modalPadding,
            '--modal-content-offset-top': isRowDirection ? '32px' : '0px',
            '--modal-content-border': isRowDirection ? '1px' : '0px',
            '--board-detail-comment-width': isRowDirection ? '420px' : null,
          }}
        >
          {isLoading || !data ? (
            <Box margin={'30vh auto'} textAlign={'center'}>
              <CircularProgress />
            </Box>
          ) : (
            <BoardDetail
              isModal={true}
              scrollRef={modalInnerRef}
              data={data}
              isRowDirection={isRowDirection}
              headerId={modalHeaderId}
              disabled={disabled}
              onChange={onChange}
              mutate={mutate}
              onDeleted={() => {
                if (onChanged) onChanged(ActionType.DELETE, documentId);
                close();
              }}
              onEdited={close}
              sx={{
                paddingBottom: isRowDirection ? '0px' : '96px',
                '--board-detail-offset-top': isRowDirection ? '56px' : '0px',
                '--board-detail-padding-top': isMobile ? '16px' : '5px',
              }}
            />
          )}
        </ModalInner>
      </Modal>
    );
  },
);

BoardDetailModal.displayName = 'BoardDetailModal';
export default BoardDetailModal;
