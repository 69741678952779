import React, { useEffect } from 'react';
import { Box, SvgIcon, Typography } from '@mui/material';
import BlackButton from '@/components/Button/BlackButton';
import LinkMenuIcon from '@/public/icons/link_menu.svg';
import { MyPageMainProps } from 'components/MenuDrawer/MyMenuDrawer/V2/Main';
import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';

const WifiContent = (props: MyPageMainProps) => {
  useEffect(() => {
    setTimeout(() => {
      console.dir(LinkMenuIcon);
    }, 5000);
  }, []);

  return (
    <Box padding={'28px 20px'}>
      <ContentDescription>
        큰길타워 방문자의 Wi-Fi 사용이 필요한 경우,
        <br />
        아래의 버튼을 눌러 나오는 화면에서 인증 후 사용 가능합니다.
        <br />
        문의사항은 정보보안팀으로 연락 바랍니다.
      </ContentDescription>
      <Typography
        sx={{
          marginTop: '16px',
          fontSize: '14px',
          a: { color: '#0066CC', textDecoration: 'underline' },
        }}
      >
        임직원은{' '}
        <a
          href={
            'https://fnf.atlassian.net/servicedesk/customer/portal/50/group/158/create/480'
          }
          target={'_blank'}
          rel={'noreferrer'}
        >
          WiFi 사용 신청
        </a>
        을 통해 기기 등록 후 사용해 주세요.
      </Typography>
      <BlackButton
        href={'https://172.21.1.2:8443/wifi.jsp'}
        target={'_blank'}
        rel={'noreferrer'}
        sx={{ marginTop: '52px' }}
      >
        <SvgIcon
          sx={{
            width: '20px',
            height: '20px',
            marginRight: '4px',
            path: { stroke: 'white' },
          }}
        >
          <LinkMenuIcon />
        </SvgIcon>
        인증화면 열기
      </BlackButton>
    </Box>
  );
};

export default WifiContent;
