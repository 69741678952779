import { AnchorHTMLAttributes } from 'react';
import { ButtonBase, ButtonBaseProps } from '@mui/material';

type WhiteButtonProps = ButtonBaseProps &
  Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'target' | 'rel'>;

export default function WhiteButton(_props: WhiteButtonProps) {
  const { children, sx, ...props } = _props;

  return (
    <ButtonBase
      {...props}
      sx={{
        display: 'flex',
        width: '100%',
        height: '48px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        color: 'black',
        fontSize: '16px',
        fontWeight: 500,
        borderRadius: '6px',
        border: '1px solid #E7E9EC',
        cursor: 'pointer',
        /*모바일에서 hover 없앰*/
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            backgroundColor: '#F8F8F8',
          },
        },
        '&:disabled, &[data-disabled=true]': {
          color: '#D0D0D0',
          backgroundColor: '#F8F8F8',
          borderColor: '#E8E8E8',
          cursor: 'default',
        },
        ...sx,
      }}
    >
      {children}
    </ButtonBase>
  );
}
