import { SubContentProps } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSettingContent';
import {
  PortalNoti,
  updateMyPortalOption,
  useMyPortalNotiList,
} from '@/hooks/apiHooks';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import useCustomToast from '@/hooks/useCustomToast';
import { ApprovalAlarmSettingProps } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/AlarmSetting/Approval';

const getActiveCategories = (approval: PortalNoti[]): string[] => {
  const alarmCategory = {
    의견추가: ['approval_opinion_attach'],
    보류: ['approval_hold', 'approval_hold_by_opinion'],
    최종승인: ['approval_confirm', 'approval_confirm_by_opinion'],
    결재: ['approval_process'],
    반려요청: ['approval_reject_request'],
    참조: ['approval_add_referee_by_opinion', 'approval_add_referee'],
  };

  const activeKeys = approval
    .filter((item) => item.webActivated)
    .map((item) => item.templateKey);

  return Object.entries(alarmCategory)
    .filter(([_, keys]) => keys.some((key) => activeKeys.includes(key)))
    .map(([category]) => category);
};

const useAlarmSettingProps = (
  settings: SubContentProps,
): ApprovalAlarmSettingProps & {
  readonly approvalSummary: string;
  readonly isJiraActivated: boolean;
  readonly jiraServiceName: string;
  readonly approvalMenuOnClick: () => void;
} => {
  const { data, mutate: updateNotiList } = useMyPortalNotiList();
  const { showSuccess, showError } = useCustomToast();

  const [isApprovalOpen, setIsApprovalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [switchValue, setSwitchValue] = useState<
    ApprovalAlarmSettingProps['approvalValues']
  >({
    all: false,
    approval_opinion_attach: false,
    approval_hold: false,
    approval_confirm: false,
    approval_reject: false,
    approval_process: false,
    approval_reject_request: false,
    approval_add_referee: false,
    approval_hold_value: 'always',
    approval_confirm_value: 'always',
    approval_add_referee_value: 'always',
  });

  const jiraActivated = data?.jira?.[0]?.webActivated ?? false;
  const jiraServiceName = data?.jira?.[0]?.description ?? 'IT Service';

  const approvalSummary = useMemo(() => {
    if (data?.approval === undefined) {
      return '';
    }

    const filteredItem = getActiveCategories(data.approval);
    switch (filteredItem.length) {
      case 0: {
        return '모든 알림이 꺼져있어요.';
      }
      case 1:
      case 2: {
        return `${filteredItem
          .map((service) => service)
          .join(', ')} 알림이 켜져 있어요`;
      }
      default: {
        return `${filteredItem
          .slice(0, 3)
          .map((service) => service)
          .join(', ')} 등 ${filteredItem.length}개 알림이 켜져 있어요`;
      }
    }
  }, [data?.approval]);

  const approvalMenuOnClick = () => {
    setIsApprovalOpen(true);
  };

  useEffect(() => {
    if (data?.approval === undefined) {
      return;
    }

    let approval_hold = false;
    let approval_confirm = false;
    let approval_add_referee = false;

    const isItOn = (keyName: string) => {
      return (
        data.approval.filter(
          (item) => item.webActivated && item.templateKey === keyName,
        )?.length > 0
      );
    };

    const filteredItem = getActiveCategories(data.approval);
    if (filteredItem.includes('보류')) {
      approval_hold = true;
    }
    if (filteredItem.includes('최종승인')) {
      approval_confirm = true;
    }
    if (filteredItem.includes('참조')) {
      approval_add_referee = true;
    }

    const approval_opinion_attach = isItOn('approval_opinion_attach');
    const approval_reject = isItOn('approval_reject');
    const approval_process = isItOn('approval_process');
    const approval_reject_request = isItOn('approval_reject_request');

    setSwitchValue({
      all:
        approval_hold &&
        approval_confirm &&
        approval_add_referee &&
        approval_opinion_attach &&
        approval_reject &&
        approval_process &&
        approval_reject_request,
      approval_opinion_attach,
      approval_hold,
      approval_confirm,
      approval_reject,
      approval_process,
      approval_reject_request,
      approval_add_referee,
      approval_hold_value: approval_hold
        ? isItOn('approval_hold')
          ? 'always'
          : 'by_opinion'
        : 'always',
      approval_confirm_value: approval_confirm
        ? isItOn('approval_confirm')
          ? 'always'
          : 'by_opinion'
        : 'always',
      approval_add_referee_value: approval_add_referee
        ? isItOn('approval_add_referee')
          ? 'always'
          : 'by_opinion'
        : 'always',
    });
  }, [data?.approval]);

  const alarmOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const approval_opinion_attach = (checked: boolean) => {
      if (checked) {
        turnOnList.push('approval_opinion_attach');
      } else {
        turnOffList.push('approval_opinion_attach');
      }
    };
    const approval_hold = (checked: boolean) => {
      //approval_hold, approval_hold_by_opinion
      if (checked) {
        turnOnList.push('approval_hold');
        turnOffList.push('approval_hold_by_opinion');
      } else {
        turnOffList.push('approval_hold', 'approval_hold_by_opinion');
      }
    };
    const approval_confirm = (checked: boolean) => {
      //approval_confirm, approval_confirm_by_opinion
      if (checked) {
        turnOnList.push('approval_confirm');
        turnOffList.push('approval_confirm_by_opinion');
      } else {
        turnOffList.push('approval_confirm', 'approval_confirm_by_opinion');
      }
    };
    const approval_reject = (checked: boolean) => {
      //approval_reject
      if (checked) {
        turnOnList.push('approval_reject');
      } else {
        turnOffList.push('approval_reject');
      }
    };
    const approval_process = (checked: boolean) => {
      //approval_process
      if (checked) {
        turnOnList.push('approval_process');
      } else {
        turnOffList.push('approval_process');
      }
    };
    const approval_reject_request = (checked: boolean) => {
      //approval_reject_request
      if (checked) {
        turnOnList.push('approval_reject_request');
      } else {
        turnOffList.push('approval_reject_request');
      }
    };
    const approval_add_referee = (checked: boolean) => {
      //approval_add_referee, approval_add_referee_by_opinion
      if (checked) {
        turnOnList.push('approval_add_referee');
        turnOffList.push('approval_add_referee_by_opinion');
      } else {
        turnOffList.push(
          'approval_add_referee',
          'approval_add_referee_by_opinion',
        );
      }
    };

    const name = event.target.name;
    const turnOnList: string[] = [];
    const turnOffList: string[] = [];

    switch (name) {
      case 'all': {
        approval_opinion_attach(checked);
        approval_hold(checked);
        approval_confirm(checked);
        approval_reject(checked);
        approval_process(checked);
        approval_reject_request(checked);
        approval_add_referee(checked);
        break;
      }
      case 'approval_opinion_attach': {
        approval_opinion_attach(checked);
        break;
      }
      case 'approval_hold': {
        approval_hold(checked);
        break;
      }
      case 'approval_confirm': {
        approval_confirm(checked);
        break;
      }
      case 'approval_reject': {
        approval_reject(checked);
        break;
      }
      case 'approval_process': {
        approval_process(checked);
        break;
      }
      case 'approval_reject_request': {
        approval_reject_request(checked);
        break;
      }
      case 'approval_add_referee': {
        approval_add_referee(checked);
        break;
      }
      case 'jira': {
        if (checked) {
          turnOnList.push('jira');
        } else {
          turnOffList.push('jira');
        }
      }
    }

    setIsLoading(true);

    try {
      await Promise.all(
        turnOnList.flatMap((id) => [
          updateMyPortalOption({
            activated: 1,
            templateKey: id,
          }),
        ]),
      );

      await Promise.all(
        turnOffList.flatMap((id) => [
          updateMyPortalOption({
            activated: 0,
            templateKey: id,
          }),
        ]),
      );

      await updateNotiList();
      showSuccess({ message: '알림 설정이 업데이트 되었습니다.' });
    } catch (e) {
      console.log('e', e);
      const error = e as any;
      showError({
        message: error?.response?.data?.messsage ?? '에러가 발생했습니다.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const alarmOptionOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setIsLoading(true);

    try {
      const name = event.target.name;

      const alwaysTemplateKey = `${name}`;
      const opinionTemplateKey = `${name}_by_opinion`;

      if (value === 'always') {
        await Promise.all([
          updateMyPortalOption({
            activated: 1,
            templateKey: alwaysTemplateKey,
          }),
          updateMyPortalOption({
            activated: 0,
            templateKey: opinionTemplateKey,
          }),
        ]);
      } else {
        await Promise.all([
          updateMyPortalOption({
            activated: 0,
            templateKey: alwaysTemplateKey,
          }),
          updateMyPortalOption({
            activated: 1,
            templateKey: opinionTemplateKey,
          }),
        ]);
      }

      await updateNotiList();
      showSuccess({ message: '알림 설정이 업데이트 되었습니다.' });
    } catch (e) {
      console.log('e', e);
      const error = e as any;
      showError({
        message: error?.response?.data?.messsage ?? '에러가 발생했습니다.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    approvalSummary,
    isJiraActivated: jiraActivated,
    jiraServiceName,
    approvalMenuOnClick,
    isApprovalOpen,
    onBackDropClick: (event: object, reason: string) => {
      if (reason === 'backdropClick') setIsApprovalOpen(false);
      else settings.menuOnClose();
    },
    approvalOnBack: () => {
      setIsApprovalOpen(false);
    },
    approvalOnClose: settings.menuOnClose,
    alarmOnChange,
    alarmOptionOnChange,
    isLoading,
    approvalValues: switchValue,
  };
};

export default useAlarmSettingProps;
