import React from 'react';
import { Box, ButtonProps, SxProps } from '@mui/material';
import { Button } from './styles';
import { Theme } from '@mui/material/styles';
import IntranetLibrarySpinner from '@/components/@IntranetLibrary/Spinner';

export type BoxButtonProps = Omit<
  ButtonProps,
  'variant' | 'disableRipple' | 'size'
> & {
  readonly variant: 'primary' | 'secondary'; //Small, float 은 나중에 구현
  readonly loading?: boolean;
  readonly size?: 'large' | 'small';
};

type TwoBoxButtonProps = {
  readonly left: Omit<BoxButtonProps, 'variant'>;
  readonly right: Omit<BoxButtonProps, 'variant'>;
  readonly variant: 'two';
  readonly sx?: SxProps<Theme>;
};

const BoxButton: React.FC<BoxButtonProps> = ({
  loading,
  children,
  variant,
  ...props
}) => {
  return (
    <>
      {loading ? (
        <Button {...props} variant={variant} disabled={true}>
          <IntranetLibrarySpinner
            theme={variant === 'primary' ? 'white' : 'black'}
          />
        </Button>
      ) : (
        <Button {...props} variant={variant}>
          {children}
        </Button>
      )}
    </>
  );
};

const TwoBoxButton: React.FC<TwoBoxButtonProps> = ({ left, right, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        gap: '8px',
        '> *': {
          flex: 1,
        },
        ...sx,
      }}
    >
      <Button {...left} variant={'secondary'} />
      <Button {...right} variant={'primary'} />
    </Box>
  );
};

const IntranetLibraryBoxButton: React.FC<BoxButtonProps | TwoBoxButtonProps> = (
  props,
) => {
  if (props.variant === 'two') {
    return <TwoBoxButton {...props} />;
  } else {
    return <BoxButton {...props} />;
  }
};

export default IntranetLibraryBoxButton;
