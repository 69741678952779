import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useCreateBoardPost,
  useSocialCategories,
  useUpdateBoardPost,
} from '@/hooks/apiHooks';
import { useCustomDialog } from '@/hooks/useCustomDialog';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MyInfoState, socialState } from '@/recoil/atom';
import { BoardFormProps } from '@/components/Board/BoardForm';
import useFormBoardData, {
  BoardFormData,
  BooleanOption,
} from '@/components/Board/BoardForm/@hooks/useBoardForm';
import {
  boardFormDataToBoardDetailPreview,
  boardFormDataToPayload,
  formatTime,
} from '@/components/Board/BoardForm/@hooks/util';
import {
  CommentPositionOptions,
  DisplaySectionOptions,
  ImportantOptions,
  UploadTimeOptions,
} from '@/components/Board/BoardForm/@hooks/constants';
import useInterval from '@/components/Board/BoardForm/@hooks/useInterval';
import { stripHTML } from '@/components/Board/BoardForm/DisplaySections';
import useLayoutSize from '@/hooks/useLayoutSize';
import { LayoutType } from '@/constant/layout';
import { BoardCommentSection, BoardDetailData } from '@/types/board';
import { BoardDetailModalRef } from '@/components/Board/BoardDetailModal/BoardDetailModal';
import useCustomToast from '@/hooks/useCustomToast';
import { Box, Typography } from '@mui/material';
import { isNativeApp } from '@/utils/userEnv';
import { PostMessageActionType, sendPostMessage } from '@/utils/postMessage';
import { fetchApi } from '@/utils/api/customAxios';
import { boardWebPushCall } from '@/components/NotiTopics';

export type BoardDetailPreviewModalRef = {
  open: (data: BoardDetailData) => void;
  close: () => void;
  isOpen: boolean;
};

export type BoardFormSettings = {
  readonly boardData?: BoardFormData;
  readonly mode: 'new' | 'edit';
  readonly defaultCategory?: string;
  readonly isModalOpen: boolean;
  readonly closeModal: () => void;
  readonly boardDetailModalRef: React.RefObject<BoardDetailModalRef>;
  readonly onWriteComplete?: () => void;
};

const useBoardFormProps = ({
  mode,
  boardData,
  defaultCategory,
  isModalOpen,
  closeModal,
  boardDetailModalRef,
  onWriteComplete,
}: BoardFormSettings): BoardFormProps => {
  const methods = useFormBoardData();
  const {
    reset,
    watch,
    getValues,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isValid, isSubmitSuccessful, dirtyFields },
  } = methods;
  const values = watch();

  const isFormDirty = Boolean(
    Object.values(dirtyFields).length > 1 ||
      stripHTML(values['content']).trim(),
  );

  const useUploadTime = values['useUploadTime'] === BooleanOption.TRUE;
  const dialog = useCustomDialog();
  const { showSuccess } = useCustomToast();

  const userInfo = useRecoilValue(MyInfoState);

  const { data: categories } = useSocialCategories(false, {
    revalidateOnFocus: false,
  });

  const [categoryData, setCategoryData] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const [autoSavedTime, setAutoSavedTime] = useState('');
  const [tempDocumentId, setTempDocumentId] = useState('');

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const BoardDetailPreviewModalRef = useRef<BoardDetailPreviewModalRef>(null);

  const getDefaultDisplayStartedAt = () => {
    const today = new Date();
    const displayStartedAt = getValues('displayStartedAt');

    if (mode === 'new') {
      return today;
    }

    if (displayStartedAt) {
      return displayStartedAt > today ? today : displayStartedAt;
    } else {
      return today;
    }
  };

  const previewData = boardFormDataToBoardDetailPreview(
    getValues(),
    categories ?? [],
    userInfo,
    getDefaultDisplayStartedAt(),
  );

  const cleanUp = () => {
    reset();
    setCategoryData([]);
    setAutoSavedTime('');
    setIsPreviewModalOpen(false);
  };

  const openPreviewModal = () => {
    const isValid = validate();

    if (!isValid) {
      dialog.show({
        message: (
          <>
            홈화면 노출 날짜가 게시글 등록일보다
            <br />
            빠르게 설정되어 있어요. 날짜를 조정해 주세요.
          </>
        ),
      });
      return;
    }

    setIsPreviewModalOpen(true);
  };

  const validate = (): boolean => {
    const useThumbnailDisplayTime = getValues('useThumbnailDisplayTime');
    const thumbnailDisplayStartDate = getValues('thumbnailDisplayStartedAt');
    const displayStartDate = getValues('displayTime');
    const useUploadTime = getValues('useUploadTime');

    if (
      useThumbnailDisplayTime === BooleanOption.FALSE ||
      useUploadTime === BooleanOption.FALSE
    ) {
      return true;
    }

    return (
      !displayStartDate ||
      !thumbnailDisplayStartDate ||
      Boolean(thumbnailDisplayStartDate >= displayStartDate)
    );
  };

  const writePost = useCallback(
    async (data: BoardFormData) => {
      try {
        BoardDetailPreviewModalRef.current?.close?.();

        const payload = boardFormDataToPayload(
          data,
          false,
          getDefaultDisplayStartedAt(),
        );

        const handleOnSuccess = (id: string) => {
          setIsPreviewModalOpen(false);
          closeModal();
          cleanUp();

          setTimeout(() => {
            onWriteComplete?.();
            boardWebPushCall();
          }, 500);

          showSuccess({
            duration: 3000,
            message: (
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Typography
                  sx={{
                    flex: 1,
                  }}
                >
                  게시글이 등록되었습니다.
                </Typography>
                <Box
                  sx={{
                    fontColor: 'rgb(30, 70, 32)',
                    marginLeft: '40px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    boardDetailModalRef.current?.open(id);
                  }}
                >
                  <Typography
                    sx={{
                      flex: 1,
                    }}
                  >
                    게시글 보기
                  </Typography>
                </Box>
              </Box>
            ),
          });
        };

        if (tempDocumentId) {
          await useUpdateBoardPost({ documentId: tempDocumentId, ...payload });
          handleOnSuccess(tempDocumentId);
        } else {
          const { id } = await useCreateBoardPost(payload);
          handleOnSuccess(id);
        }
      } catch (error) {
        dialog.show({
          title: `게시글 ${mode === 'new' ? '작성' : '수정'}에 실패했습니다`,
          message: `${error}`,
        });
      }
    },
    [tempDocumentId, mode],
  );

  const saveDraft = useCallback(
    async (showErrorMessage?: boolean) => {
      if (isPreviewModalOpen) {
        return;
      }

      try {
        const payload = boardFormDataToPayload(
          getValues(),
          true,
          getDefaultDisplayStartedAt(),
        );

        if (tempDocumentId) {
          await useUpdateBoardPost({
            ...payload,
            documentId: tempDocumentId,
          });

          setAutoSavedTime(formatTime(new Date()));
        } else {
          const { id, createdAt, updatedAt } = await useCreateBoardPost(
            payload,
          );

          const updatedDate = new Date(updatedAt);
          reset((prev) => ({
            ...prev,
            id,
            createdTime: new Date(createdAt),
            updatedTime: updatedDate,
          }));

          setAutoSavedTime(formatTime(updatedDate));
          setTempDocumentId(id);
        }
      } catch (e) {
        if (showErrorMessage) {
          dialog.show({
            title: `임시저장에 실패했습니다`,
          });
        }
      }
    },
    [tempDocumentId, mode],
  );

  const handleFormSubmit: BoardFormProps['handleFormSubmit'] = (e) => {
    e.preventDefault();
    handleSubmit(writePost)();
  };

  const handleModalOnClose = () => {
    const close = () => {
      closeModal();
      cleanUp();
    };

    if (isSubmitSuccessful) {
      close();
    } else {
      dialog.show({
        title:
          mode === 'new'
            ? '작성화면을 닫으시겠어요?'
            : '작성 화면을 나가시겠어요?',
        message:
          mode === 'new'
            ? '임시저장 되지 않은 내용은 사라집니다.'
            : '수정한 내용은 사라집니다.',
        confirmButtonTitle: '나가기',
        onConfirm: close,
        dismissButtonTitle: '취소',
      });
    }
  };

  useEffect(() => {
    if (!isModalOpen) {
      return;
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (mode === 'edit' || isFormDirty || !isSubmitSuccessful) {
        event.preventDefault();
        event.returnValue = `작성화면을 닫으시겠어요?\n임시저장 되지 않은 내용은 사라집니다.`;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [mode, isFormDirty, isSubmitSuccessful, isModalOpen]);

  useEffect(() => {
    if (boardData) {
      reset(boardData);
      setTempDocumentId(boardData.id ?? '');
      setAutoSavedTime(formatTime(boardData.updatedTime));
    }
  }, [boardData, reset]);

  useEffect(() => {
    if (categories) {
      setCategoryData(
        categories
          .map((cat) => ({
            label: cat.name,
            value: cat.id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      );
    }
  }, [categories]);

  useEffect(() => {
    if (defaultCategory) {
      setValue('categories', [defaultCategory]);
    }
  }, [defaultCategory]);

  useEffect(() => {
    if (mode === 'new' && isNativeApp()) {
      if (isModalOpen) {
        sendPostMessage(PostMessageActionType.NAVI_CLOSE);
      } else {
        sendPostMessage(PostMessageActionType.NAVI_OPEN);
      }
    }
  }, [mode, isModalOpen]);

  useInterval(
    useCallback(async () => {
      if (isModalOpen && (isFormDirty || boardData?.id) && mode === 'new') {
        await saveDraft();
      }
    }, [isFormDirty, saveDraft, mode, boardData?.id, isModalOpen]),
    60 * 1000,
  );

  return {
    mode,
    methods,
    formTitle: mode === 'new' ? '게시글 작성' : '게시글 수정',
    categoryOptions: categoryData,
    category: values.categories
      .map((c) => categoryData.find((n) => n.value === c))
      .filter(
        (item): item is { value: string; label: string } => item !== undefined,
      ),
    displaySectionOptions: DisplaySectionOptions,
    commentPositionOptions: CommentPositionOptions,
    uploadTimeOptions: UploadTimeOptions,
    showUploadTimeSelector: useUploadTime,
    importantOptions: ImportantOptions,
    autoSavedTime: mode === 'new' ? autoSavedTime : '',
    submitButtonType: mode === 'new' ? 'two' : 'primary',
    primaryButtonOnClick: openPreviewModal,
    secondaryButtonOnClick: () => saveDraft(true),
    isSubmitButtonDisabled: !isValid || isSubmitting,
    modalOnClose: handleModalOnClose,
    isModalOpen,
    previewModalOnClose: () => {
      setIsPreviewModalOpen(false);
    },
    isPreviewModalOpen,
    previewData,
    handleFormSubmit,
    contentWidth: useChildrenWidth(),
    ...useBoardDetailProps({
      isPreviewModalOpen,
      commentSection: previewData?.config?.commentSection,
    }),
    ...useSlideProps(isPreviewModalOpen),
  };
};

export default useBoardFormProps;

function useChildrenWidth() {
  const { isMobile } = useLayoutSize(LayoutType.BOARD_DETAIL_MODAL);

  const [childrenWidth, setChildrenWidth] = useState(944);

  const viewportWidth = window.innerWidth;

  const calculateWidth = useCallback(() => {
    const baseWidth = viewportWidth - 80;
    const minWidth = 944;
    const maxWidth = 1186;

    return Math.max(minWidth, Math.min(baseWidth, maxWidth));
  }, []);

  useEffect(() => {
    function handleResize() {
      const newWidth = calculateWidth();
      setChildrenWidth(newWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [calculateWidth]);

  return isMobile ? '100%' : `${childrenWidth}px`;
}

function useSlideProps(isPreviewModalOpen: boolean) {
  const modalScrollRef = useRef<HTMLDivElement>(null);
  const modalBoardFormRef = useRef<HTMLDivElement>(null);
  const modalPreviewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const parent = modalScrollRef.current;

    if (!parent) {
      return;
    }

    parent.scrollTo(0, 0);

    const handleScroll = (event: WheelEvent) => {
      const activeSlide = isPreviewModalOpen
        ? modalPreviewRef.current
        : modalBoardFormRef.current;

      if (activeSlide) {
        const limit = activeSlide.getBoundingClientRect().height;

        const { currentTarget: target } = event;
        const element = target as HTMLDivElement;
        if (element) {
          const { scrollTop, clientHeight } = element;

          if (scrollTop > limit - clientHeight) {
            parent.scrollTo(0, limit - clientHeight);
          }
        }
      }
    };

    parent.addEventListener('scroll', handleScroll as EventListener, {
      passive: false,
    });

    return () => {
      parent.removeEventListener('scroll', handleScroll as EventListener);
    };
  }, [isPreviewModalOpen]);

  return {
    modalScrollRef,
    modalBoardFormRef,
    modalPreviewRef,
  };
}

function useBoardDetailProps({
  commentSection,
}: {
  commentSection?: BoardCommentSection;
  isPreviewModalOpen: boolean;
}) {
  const { isMobile, isSmallDesktop } = useLayoutSize(
    LayoutType.BOARD_DETAIL_MODAL,
  );

  const [isRowDirection, setIsRowDirection] = useState(false);

  const modalInnerRef = useRef<HTMLDivElement>(null);
  const [modalPadding, setModalPadding] = useState(isMobile ? '20px' : '24px');

  useEffect(() => {
    setIsRowDirection(
      !isMobile &&
        !isSmallDesktop &&
        commentSection === BoardCommentSection.RIGHT,
    );
    setModalPadding(isMobile ? '20px' : '24px');
  }, [commentSection, isMobile, isSmallDesktop]);

  return {
    isMobile,
    isRowDirection,
    modalPadding,
    modalInnerRef,
  };
}
