import { styled } from '@mui/material/styles';
import React from 'react';
import { BoxProps } from '@mui/material/Box';
import { Box, Stack, StackProps } from '@mui/material';
import RHFTextField, {
  RHFTextFieldProps,
} from '@/components/HookForm/RHFTextField';
import IntranetLibraryBoxButton, {
  BoxButtonProps,
} from '@/components/@IntranetLibrary/BoxButton';

export const TextFieldWithButton = styled(
  ({ sx, ...props }: RHFTextFieldProps) => (
    <Stack sx={{ flex: 1 }}>
      <RHFTextField
        {...props}
        sx={{
          flex: 1,

          '& .MuiInputBase-root': {
            height: '48px',
            fontSize: '14px',
            lineHeight: '20px',
          },

          ...sx,
        }}
      />
    </Stack>
  ),
)(() => ({}));

export const TextFieldButton = styled(
  (props: Omit<BoxButtonProps, 'variant' | 'size'>) => (
    <IntranetLibraryBoxButton variant={'primary'} size={'small'} {...props} />
  ),
)(() => ({
  minWidth: '100px',
}));

export const History = styled(({ children, ...props }: StackProps) => (
  <Stack {...props}>{children}</Stack>
))(() => ({
  padding: '12px',
  gap: '8px',
  borderRadius: '8px',
  background: '#F9FAFB',
}));

export const HistoryItem = styled(({ children, ...props }: BoxProps) => (
  <Box {...props}>{children}</Box>
))(() => ({
  display: 'flex',
  '*:first-of-type': {
    flex: 'none',
    width: '80px',
  },
  '*:last-of-type': {
    flex: 1,
    wordBreak: 'break-word',
  },
}));
