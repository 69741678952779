import React, { useCallback } from 'react';

function useUserProfilePopper<HTMLElementType extends HTMLElement>(
  passportId: string,
  handleOnOpen?: (element: HTMLElementType) => void,
) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElementType | null>(null);

  const onClickAwayPopper = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (
        !anchorEl ||
        (event.target && anchorEl.contains(event.target as HTMLElementType))
      ) {
        return;
      }

      setAnchorEl(null);
    },
    [anchorEl],
  );

  const onOpenPopper = useCallback(
    (event: React.MouseEvent<HTMLElementType>) => {
      const el = event.currentTarget as HTMLElementType;
      setAnchorEl(el);
      handleOnOpen?.(el);
    },
    [],
  );

  const onClosePopper = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return {
    anchorEl,
    passportId,
    onOpenPopper,
    onClosePopper,
    onClickAwayPopper,
  };
}

export default useUserProfilePopper;
