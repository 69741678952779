import React from 'react';
import Popper from '@mui/material/Popper';
import {
  Box,
  Button,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { imgUrlChk } from '@/utils/function';
import ImageWithErrorHandling from '@/components/ImageWithErrorHandling';
import { useFindPassport } from '@/hooks/apiHooks';
import MsTeamsIcon from '@/public/images/icon/ms_teams.svg';
import { UserProfilePopperZIndex } from '@/constant/z-index';
import { copyClipBoardText } from '@/utils';
import useCustomToast from '@/hooks/useCustomToast';
import useLayoutSize from '@/hooks/useLayoutSize';
import CloseIcon from '@/public/icons/close_black.svg';
import CopyIcon from '@/public/icons/copy-2.svg';
import WhiteButton from '@/components/Button/WhiteButton';
import process from 'process';

type UserProfilePopperProps = {
  anchorEl: HTMLElement | null;
  passportId: string;
  onClose: () => void;
  onClickAway?: (event: MouseEvent | TouchEvent) => void;
};

const UserProfilePopper = (props: UserProfilePopperProps) => {
  const { isMobile } = useLayoutSize();
  const { anchorEl, passportId, onClose, onClickAway } = props;
  const open = Boolean(anchorEl);
  const { data, isLoading } = useFindPassport(passportId);
  const toast = useCustomToast();

  const copyProfileCardLink = async () => {
    if (!data?.passportId) return;
    const resultCopy = await copyClipBoardText(
      `${process.env.NEXT_PUBLIC_ORIGIN_URL}?search=${data.passportId}`,
    );
    if (resultCopy) {
      toast.show({
        severity: 'success',
        message: '프로필 카드 링크가 복사되었습니다.',
        duration: 3000,
      });
    }
  };

  const copyEmail = async () => {
    if (!data?.detail.email) return;
    const resultCopy = await copyClipBoardText(data.detail.email);
    if (resultCopy) {
      toast.show({
        severity: 'success',
        message: '이메일이 복사되었습니다.',
        duration: 3000,
      });
    }
  };

  return (
    <ClickAwayListener
      onClickAway={(event) => {
        onClickAway?.(event);
      }}
    >
      <Popper
        id={`user-profile-${passportId}`}
        open={open}
        anchorEl={anchorEl}
        placement={'bottom-start'}
        disablePortal={true}
        modifiers={[
          {
            name: 'preventOverflow',
            options: { padding: 10 },
          },
        ]}
        sx={{ zIndex: UserProfilePopperZIndex }}
      >
        <Box
          sx={{
            width: isMobile ? 'auto' : '320px',
            minHeight: '108px',
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.08)',
            backgroundColor: 'white',
          }}
        >
          <Stack
            direction={'row'}
            spacing={'4px'}
            sx={{
              position: 'absolute',
              top: '12px',
              right: '12px',
              button: { padding: '4px' },
            }}
          >
            {data?.passportId && (
              <Tooltip
                title={'프로필 카드 링크 복사'}
                placement="top"
                arrow
                slotProps={{
                  tooltip: { sx: { backgroundColor: 'black', color: 'white' } },
                  arrow: { sx: { color: 'black' } },
                  popper: {
                    modifiers: [
                      { name: 'offset', options: { offset: [0, -8] } },
                    ],
                  },
                }}
              >
                <Button onClick={copyProfileCardLink}>
                  <CopyIcon width={'20px'} height={'20px'} />
                </Button>
              </Tooltip>
            )}
            <Button onClick={onClose}>
              <CloseIcon width={'20px'} height={'20px'} />
            </Button>
          </Stack>
          {!data ? (
            <Typography fontSize={'14px'} margin={'30px auto 25px'}>
              {isLoading ? (
                '불러오는 중...'
              ) : (
                <>
                  프로필 카드가 없습니다.
                  <br />
                  휴직 혹은 퇴사자일 수 있습니다.
                </>
              )}
            </Typography>
          ) : (
            <Stack direction={'column'} spacing={'16px'}>
              <Stack direction={'column'} spacing={'8px'}>
                <ImageWithErrorHandling
                  src={imgUrlChk(data.detail.profileImageUrl)}
                  width={100}
                  height={100}
                  alt={'profile-image'}
                  style={{
                    margin: '0 auto',
                    borderRadius: '4px',
                    objectFit: 'cover',
                  }}
                />
                <Box
                  sx={{
                    '> p': { textAlign: 'center', margin: 0 },
                    '.name': { fontSize: '14px' },
                    '.positionName': {
                      marginTop: '4px',
                      fontSize: '12px',
                      color: '#888D96',
                    },
                  }}
                >
                  <p className={'name'}>
                    {data.detail.name}{' '}
                    {data.detail.nickname && `(${data.detail.nickname})`}
                  </p>
                  <p className={'positionName'}>{data.detail.positionName}</p>
                </Box>
                <Box
                  sx={{
                    padding: '8px 0',
                    borderTop: '1px solid #E7E9EC',
                    borderBottom: '1px solid #E7E9EC',
                    textAlign: 'center',
                    fontSize: '12px',
                    color: '#888D96',
                    wordBreak: 'keep-all',
                  }}
                >
                  {data.detail.companyName} · {data.detail.divisionName} ·{' '}
                  {data.detail.departmentName}
                </Box>
              </Stack>
              <Stack
                direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={'8px'}
                sx={{
                  a: {
                    fontSize: '12px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  },
                }}
              >
                <Stack
                  direction={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  divider={
                    <Divider
                      orientation={'vertical'}
                      sx={{
                        height: '8px',
                        marginX: '8px',
                        borderColor: '#D4D7DB',
                      }}
                    />
                  }
                >
                  {data.detail.phoneInside && (
                    <a href={`tel:${data.detail.phoneInside}`}>
                      {data.detail.phoneInside}
                    </a>
                  )}
                  <a href={`tel:${data.detail.phoneMobile}`}>
                    {data.detail.phoneMobile}
                  </a>
                </Stack>
                {data.detail.email && (
                  <a onClick={copyEmail}>{data.detail.email}</a>
                )}
              </Stack>
              <WhiteButton
                href={`https://teams.microsoft.com/l/chat/0/0?users=${data.detail.email}`}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <MsTeamsIcon />
                <Typography
                  sx={{ marginLeft: '4px', fontWeight: 400, fontSize: '14px' }}
                >
                  Open Teams
                </Typography>
              </WhiteButton>
            </Stack>
          )}
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default UserProfilePopper;
