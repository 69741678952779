import React from 'react';
import { Link, LinkProps } from '@mui/material';
import { getInternalLibraryButtonStyles } from '@/components/@IntranetLibrary/BoxButton/styles';
import { BoxButtonProps } from '@/components/@IntranetLibrary/BoxButton';

export type IntranetLibraryLinkProps = Omit<
  LinkProps,
  'variant' | 'disableRipple' | 'size'
> &
  Pick<BoxButtonProps, 'variant' | 'size'>;

const IntranetLibraryLink: React.FC<IntranetLibraryLinkProps> = ({
  children,
  variant,
  size,
  sx,
  ...props
}) => {
  return (
    <Link
      {...props}
      sx={{
        ...getInternalLibraryButtonStyles(size, variant),
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        textDecoration: 'none',
        textAlign: 'center',
        ...sx,
      }}
    >
      {children}
    </Link>
  );
};

export default IntranetLibraryLink;
