import { LanguageSettingContentProps } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSettingContent';
import React, { useState } from 'react';
import { Language, LanguageValue, MyInfo } from '@/types/api';
import { useCustomDialog } from '@/hooks/useCustomDialog';
import { useChangeLanguage } from '@/hooks/apiHooks';
import { RnMessage, sendToRN } from '@/utils/function';
import { useRouter } from 'next/router';

const useLanguageSettingContentProps = ({
  myInfo,
}: {
  myInfo: MyInfo;
}): LanguageSettingContentProps => {
  const { language = 'ko' } = myInfo;
  const [selectedLanguage, setSelectedLanguage] =
    useState<LanguageValue>(language);
  const router = useRouter();
  const dialog = useCustomDialog();

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as LanguageValue;
    setSelectedLanguage(value);
  };

  const handleChange = () => {
    dialog.show({
      message: `언어 설정을 ‘${Language[selectedLanguage]}’로 변경하시겠습니까?`,
      dismissButtonTitle: '취소',
      confirmButtonTitle: '변경하기',
      onConfirm: () => {
        useChangeLanguage(selectedLanguage).then((_) => {
          window.isRNWebview ? sendToRN(RnMessage.RESTART) : router.reload();
        });
      },
    });
  };

  return {
    language,
    selectedLanguage,
    selectOptions: Object.keys(Language)
      .filter((item) => item !== 'default')
      .map((item) => ({
        value: item,
        label: Language[item as LanguageValue],
      })),
    handleSelect,
    handleChange,
  };
};

export default useLanguageSettingContentProps;
