import React from 'react';
import { Divider, Drawer, DrawerProps, Stack } from '@mui/material';
import {
  MyMenuContentWrapper,
  MyMenuHeader,
} from '@/components/MenuDrawer/MyMenuDrawer/V2/styles';
import IntranetLibrarySwitch from '@/components/@IntranetLibrary/Switch';
import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import {
  ItemContent,
  ItemTitle,
  ItemWrapper,
  RadioSelect,
  RadioSelectWrapper,
  Section,
  SectionContent,
  SectionTitle,
} from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/AlarmSetting/Approval/style';
import { SwitchProps } from '@mui/material/Switch';
import { IntranetLibraryRadioProps } from '@/components/@IntranetLibrary/Radio';

export type ApprovalAlarmSettingProps = {
  readonly isApprovalOpen: boolean;
  readonly approvalOnBack: () => void;
  readonly approvalOnClose: () => void;
  readonly onBackDropClick: DrawerProps['onClose'];
  readonly isLoading: boolean;
  readonly alarmOnChange: SwitchProps['onChange'];
  readonly alarmOptionOnChange: IntranetLibraryRadioProps<string>['onChange'];
  readonly approvalValues: {
    readonly all: boolean;
    readonly approval_opinion_attach: boolean;
    readonly approval_hold: boolean;
    readonly approval_confirm: boolean;
    readonly approval_reject: boolean;
    readonly approval_process: boolean;
    readonly approval_reject_request: boolean;
    readonly approval_add_referee: boolean;
    readonly approval_hold_value: ApprovalRadioValue;
    readonly approval_confirm_value: ApprovalRadioValue;
    readonly approval_add_referee_value: ApprovalRadioValue;
  };
};

type ApprovalRadioValue = 'always' | 'by_opinion';

const ApprovalAlarmSetting = ({
  isApprovalOpen,
  approvalOnBack,
  approvalOnClose,
  onBackDropClick,
  alarmOnChange,
  alarmOptionOnChange,
  isLoading,
  approvalValues,
}: ApprovalAlarmSettingProps) => {
  return (
    <Drawer
      key={'AlarmApprovalSetting'}
      id={'AlarmApprovalSetting'}
      open={isApprovalOpen}
      onClose={onBackDropClick}
      anchor={'right'}
      ModalProps={{
        sx: { '.MuiModal-backdrop': { opacity: '0 !important' } },
      }}
    >
      <MyMenuHeader
        onBack={approvalOnBack}
        onClose={approvalOnClose}
        hasBackButton={true}
        title={'APPROVAL'}
      />
      <MyMenuContentWrapper>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          sx={{ padding: '0 20px 80px', gap: '16px' }}
        >
          <ContentDescription>
            <ItemWrapper>
              <ItemTitle>모두 켜기</ItemTitle>
              <ItemContent>
                <IntranetLibrarySwitch
                  onChange={alarmOnChange}
                  disabled={isLoading}
                  name={'all'}
                  checked={approvalValues['all']}
                />
              </ItemContent>
            </ItemWrapper>
          </ContentDescription>

          <Section>
            <SectionTitle>내가 작성자인 문서</SectionTitle>
            <SectionContent>
              <ItemWrapper>
                <ItemTitle>중간에 의견이 추가됐을 때</ItemTitle>
                <ItemContent>
                  <IntranetLibrarySwitch
                    onChange={alarmOnChange}
                    disabled={isLoading}
                    name={'approval_opinion_attach'}
                    checked={approvalValues['approval_opinion_attach']}
                  />
                </ItemContent>
              </ItemWrapper>
              <RadioSelectWrapper>
                <ItemWrapper>
                  <ItemTitle>보류됐을 때</ItemTitle>
                  <ItemContent>
                    <IntranetLibrarySwitch
                      onChange={alarmOnChange}
                      disabled={isLoading}
                      name={'approval_hold'}
                      checked={approvalValues['approval_hold']}
                    />
                  </ItemContent>
                </ItemWrapper>
                <RadioSelect
                  name={'approval_hold'}
                  onChange={alarmOptionOnChange}
                  value={approvalValues['approval_hold_value']}
                  disabled={isLoading || !approvalValues['approval_hold']}
                  options={[
                    { label: '항상 받기', value: 'always' },
                    {
                      label: '의견 있을 때만 받기',
                      value: 'by_opinion',
                    },
                  ]}
                />
              </RadioSelectWrapper>
              <RadioSelectWrapper>
                <ItemWrapper>
                  <ItemTitle>최종 승인됐을 때</ItemTitle>
                  <ItemContent>
                    <IntranetLibrarySwitch
                      onChange={alarmOnChange}
                      disabled={isLoading}
                      name={'approval_confirm'}
                      checked={approvalValues['approval_confirm']}
                    />
                  </ItemContent>
                </ItemWrapper>
                <RadioSelect
                  name={'approval_confirm'}
                  value={approvalValues['approval_confirm_value']}
                  onChange={alarmOptionOnChange}
                  disabled={isLoading || !approvalValues['approval_confirm']}
                  options={[
                    { label: '항상 받기', value: 'always' },
                    {
                      label: '의견 있을 때만 받기',
                      value: 'by_opinion',
                    },
                  ]}
                />
              </RadioSelectWrapper>
              <ItemWrapper>
                <ItemTitle>반려됐을 때</ItemTitle>
                <ItemContent>
                  <IntranetLibrarySwitch
                    onChange={alarmOnChange}
                    disabled={isLoading}
                    name={'approval_reject'}
                    checked={approvalValues['approval_reject']}
                  />
                </ItemContent>
              </ItemWrapper>
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>내가 결재자인 문서</SectionTitle>
            <SectionContent>
              <ItemWrapper>
                <ItemTitle>결재할 차례일 때</ItemTitle>
                <ItemContent>
                  <IntranetLibrarySwitch
                    onChange={alarmOnChange}
                    disabled={isLoading}
                    name={'approval_process'}
                    checked={approvalValues['approval_process']}
                  />
                </ItemContent>
              </ItemWrapper>
              <ItemWrapper>
                <ItemTitle>반려 요청이 왔을 때</ItemTitle>
                <ItemContent>
                  <IntranetLibrarySwitch
                    onChange={alarmOnChange}
                    disabled={isLoading}
                    name={'approval_reject_request'}
                    checked={approvalValues['approval_reject_request']}
                  />
                </ItemContent>
              </ItemWrapper>
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>내가 참조자인 문서</SectionTitle>
            <SectionContent>
              <RadioSelectWrapper>
                <ItemWrapper>
                  <ItemTitle>참조인으로 추가됐을 때</ItemTitle>
                  <ItemContent>
                    <IntranetLibrarySwitch
                      onChange={alarmOnChange}
                      disabled={isLoading}
                      name={'approval_add_referee'}
                      checked={approvalValues['approval_add_referee']}
                    />
                  </ItemContent>
                </ItemWrapper>
                <RadioSelect
                  name={'approval_add_referee'}
                  onChange={alarmOptionOnChange}
                  value={approvalValues['approval_add_referee_value']}
                  disabled={isLoading || approvalValues['approval_add_referee']}
                  options={[
                    { label: '항상 받기', value: 'always' },
                    {
                      label: '의견 있을 때만 받기',
                      value: 'by_opinion',
                    },
                  ]}
                />
              </RadioSelectWrapper>
            </SectionContent>
          </Section>
        </Stack>
      </MyMenuContentWrapper>
    </Drawer>
  );
};

export default ApprovalAlarmSetting;
