import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { SubContentProps } from '../../SubMenu/LanguageSettingContent';
import useCertificateOfEmploymentProps, {
  EmpCertificateRequestData,
} from './@hooks/useCertificateOfEmploymentProps';
import FormProvider, {
  FormProviderProps,
} from '@/components/HookForm/FormProvider';
import IntranetLibraryLabel from '@/components/@IntranetLibrary/Label';
import IntranetLibraryBoxButton from '@/components/@IntranetLibrary/BoxButton';
import { FormGroup } from '@/components/Board/BoardForm/style';
import RHFTextField, {
  RHFTextFieldProps,
} from '@/components/HookForm/RHFTextField';
import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import {
  IntranetLibraryBody1,
  IntranetLibraryBody2,
} from '@/components/@IntranetLibrary/Typography';
import EmptyContent from '@/components/EmptyContent';
import {
  History,
  HistoryItem,
  TextFieldButton,
  TextFieldWithButton,
} from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/CertificateOfEmployment/style';
import RHFTextArea from '@/components/HookForm/RHFTextarea';

export type CertificateOfEmploymentProps = {
  readonly formProps: FormProviderProps;
  readonly isLoading: boolean;
  readonly isCodeRequested: boolean;
  readonly isVerified: boolean;

  readonly phoneNumberOnChange: RHFTextFieldProps['onChange'];
  readonly phoneNumberOnClear: RHFTextFieldProps['onClear'];
  readonly requestCodeButtonTitle: string;
  readonly requestButtonOnClick: () => void;
  readonly isRequestButtonDisabled: boolean;
  readonly codeHelperText?: string;
  readonly verifyButtonOnClick: () => void;
  readonly isVerifyButtonDisabled: boolean;
  readonly isSubmitButtonDisabled: boolean;
  readonly isRequestHistoryLoading: boolean;
  readonly requestHistory: EmpCertificateRequestData[];
};

const CertificateOfEmployment = (props: SubContentProps) => {
  const {
    formProps,
    phoneNumberOnChange,
    phoneNumberOnClear,
    requestCodeButtonTitle,
    requestButtonOnClick,
    isRequestButtonDisabled,
    isLoading,
    isCodeRequested,
    isVerified,
    codeHelperText,
    verifyButtonOnClick,
    isVerifyButtonDisabled,
    isSubmitButtonDisabled,
    isRequestHistoryLoading,
    requestHistory,
  } = useCertificateOfEmploymentProps(props);

  return (
    <Stack
      divider={<Divider orientation="horizontal" flexItem />}
      sx={{ padding: '0 20px' }}
    >
      <Stack sx={{ padding: '28px 0' }}>
        <ContentDescription>
          문자 인증을 통해 직접 재직증명서를 발급할 수 있습니다.
        </ContentDescription>
      </Stack>
      <FormProvider {...formProps}>
        <Stack sx={{ padding: '24px 0 32px', gap: '24px' }}>
          <FormGroup gapsize={'s'}>
            <IntranetLibraryLabel
              isRequired={true}
              title={'휴대폰 번호'}
              size={14}
            />
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                width: '100%',
              }}
            >
              <TextFieldWithButton
                name={'phoneNumber'}
                variant={'outlined'}
                onChange={phoneNumberOnChange}
                onClear={phoneNumberOnClear}
                disabled={isLoading || isVerified}
              />
              <TextFieldButton
                onClick={requestButtonOnClick}
                disabled={isRequestButtonDisabled}
                loading={isLoading}
              >
                {requestCodeButtonTitle}
              </TextFieldButton>
            </Box>
          </FormGroup>
          {isCodeRequested && (
            <FormGroup gapsize={'s'}>
              <IntranetLibraryLabel
                isRequired={true}
                title={'인증번호'}
                size={14}
              />
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  width: '100%',
                }}
              >
                <TextFieldWithButton
                  name={'verificationCode'}
                  helperText={
                    codeHelperText && (
                      <Typography sx={{ color: '#43B16F', fontSize: '12px' }}>
                        {codeHelperText}
                      </Typography>
                    )
                  }
                  disabled={isLoading || isVerified}
                  variant={'outlined'}
                  placeholder={'인증번호 4자리'}
                  type={'number'}
                />
                <TextFieldButton
                  onClick={verifyButtonOnClick}
                  disabled={isVerifyButtonDisabled}
                  loading={isLoading}
                >
                  인증
                </TextFieldButton>
              </Box>
            </FormGroup>
          )}
          {isVerified && (
            <>
              <FormGroup gapsize={'s'}>
                <IntranetLibraryLabel
                  isRequired={true}
                  title={'사용 용도'}
                  size={14}
                />
                <RHFTextArea
                  name={'purpose'}
                  placeholder={'50자 이내로 작성해주세요'}
                  minRows={1}
                />
              </FormGroup>
              <IntranetLibraryBoxButton
                variant={'primary'}
                size={'large'}
                type={'submit'}
                disabled={isSubmitButtonDisabled}
                loading={isLoading}
                sx={{ width: '100%' }}
              >
                발급
              </IntranetLibraryBoxButton>
            </>
          )}
        </Stack>
      </FormProvider>
      <Stack sx={{ padding: '24px 0 80px' }}>
        <IntranetLibraryBody1 sx={{ fontWeight: 500 }}>
          증명서 발급이력
        </IntranetLibraryBody1>
        {isRequestHistoryLoading ? (
          <></>
        ) : requestHistory.length > 0 ? (
          <HistoryList requestHistory={requestHistory} />
        ) : (
          <Box sx={{ marginTop: '28px' }}>
            <EmptyContent title={'발급 이력이 없습니다.'} type={'empty'} />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default CertificateOfEmployment;

const HistoryList = ({
  requestHistory: list,
}: Pick<CertificateOfEmploymentProps, 'requestHistory'>) => {
  return (
    <Stack gap={'16px'} marginTop={'16px'}>
      {list.map(({ id, requestDate, purpose = '-' }) => (
        <History key={id} sx={{ gap: '8px' }}>
          {[
            { title: '발급일', content: requestDate },
            { title: '발급 번호', content: id },
            { title: '사용 용도', content: purpose },
          ].map(({ title, content }, index) => (
            <HistoryItem key={`${id}${index}`}>
              <IntranetLibraryBody2>{title}</IntranetLibraryBody2>
              <IntranetLibraryBody2 sx={{ color: '#000000' }}>
                {content}
              </IntranetLibraryBody2>
            </HistoryItem>
          ))}
        </History>
      ))}
    </Stack>
  );
};
