import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export type CertificateRequestForm = {
  phoneNumber: string;
  verificationCode: string;
  verificationId: string;
  verified: boolean;
  purpose: string;
};

const defaultValues: CertificateRequestForm = {
  phoneNumber: '',
  verificationCode: '',
  verificationId: '',
  verified: false,
  purpose: '',
};

const Schema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('핸드폰 번호를 입력해주세요')
    .matches(
      /^\d{3}-\d{3,4}-\d{4}$/,
      '올바른 형식의 핸드폰 번호를 입력해주세요',
    ),
  verificationCode: Yup.string()
    .min(4, '인증번호를 입력해주세요')
    .max(10, '인증번호를 입력해주세요')
    .matches(/^[0-9]+$/, '숫자만 입력해주세요'),
  verificationId: Yup.string().required(''),
  verified: Yup.boolean().required(),
  purpose: Yup.string()
    .required('사용 용도를 50자 이내로 입력해주세요')
    .max(50, '사용 용도를 50자 이내로 입력해주세요'),
});

const useCertificateRequestForm = () => {
  return useForm<CertificateRequestForm>({
    resolver: yupResolver(Schema),
    defaultValues,
    mode: 'onChange',
  });
};

export default useCertificateRequestForm;
