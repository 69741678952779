import React from 'react';
import { Stack, StackProps, styled } from '@mui/material';

export const BoardDetailLikesStack = styled(
  ({ children, sx, ...props }: StackProps) => (
    <Stack
      direction={'row'}
      justifyContent={'flex-start'}
      alignContent={'flex-start'}
      alignItems={'flex-start'}
      gap={'6px'}
      flexWrap={'wrap'}
      sx={{
        padding: '24px 0 0',
        'button.likeUser': {
          display: 'inline-block',
          padding: '2px',
          fontSize: '14px',
          color: 'black',
          '&:not(:last-of-type)::after': {
            content: '","',
          },
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Stack>
  ),
)();
