import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Password } from '@/components/Profile/SecondItem/PassWord';

export type PasswordForm = Password;
const defaultValues: PasswordForm = {
  currentPassword: '',
  updatePassword: '',
  updateConfirmPassword: '',
};

const BaseRule = Yup.string().matches(
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d~!@#$%^&*()_+\-={}[\]|\\:;"'<>,.?/]{8,16}$/,
  '8~16자의 영문, 숫자, 특수문자 사용',
);
const Schema = Yup.object().shape({
  currentPassword: BaseRule,
  updatePassword: BaseRule,
  updateConfirmPassword: BaseRule.oneOf(
    [Yup.ref('updatePassword')],
    '비밀번호가 일치하지 않습니다',
  ).required('비밀번호가 일치하지 않습니다.'),
});

const usePasswordForm = () => {
  return useForm<PasswordForm>({
    resolver: yupResolver(Schema),
    defaultValues,
    mode: 'onChange',
  });
};

export default usePasswordForm;
