import React, { PropsWithChildren } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { SubContentProps } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSettingContent';
import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import {
  IntranetLibraryBody1,
  IntranetLibraryBody2,
  IntranetLibraryCaption,
} from '@/components/@IntranetLibrary/Typography';
import IntranetLibraryLink from '@/components/@IntranetLibrary/Link';
import IntranetLibraryLabel from '@/components/@IntranetLibrary/Label';
import { FormGroup } from '@/components/Board/BoardForm/style';
import RHFPasswordField from '@/components/HookForm/RHFPasswordField';
import FormProvider from '@/components/HookForm/FormProvider';
import usePasswordSettingProps from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/PasswordSetting/@hooks/usePasswordSettingProps';
import IntranetLibraryBoxButton from '@/components/@IntranetLibrary/BoxButton';

const PasswordSetting = (props: SubContentProps) => {
  const { passwordSettingLink, formProps, isSubmitButtonDisabled, isLoading } =
    usePasswordSettingProps(props);

  return (
    <Stack>
      <Box
        sx={{
          padding: '28px 20px',
        }}
      >
        <ContentDescription>
          회사에서 사용하는 계정은 F&F 계정과 MS Office 365 계정으로 구분됩니다.
          <br />
          계정별 사용 시스템 확인 후 변경해 주세요.
        </ContentDescription>
      </Box>
      <Stack>
        <FormProvider {...formProps}>
          <SectionHeader
            title={'F&F 계정'}
            subTitle={'사용 시스템'}
            tags={['Finstagram', 'M ERP', 'SCM', 'SMS', 'PDM']}
          />
          <Stack sx={{ padding: '24px 20px 32px', gap: '24px' }}>
            <FormGroup gapsize={'s'}>
              <IntranetLibraryLabel
                isRequired={true}
                title={'현재 비밀번호'}
                size={14}
              />
              <RHFPasswordField
                name={'currentPassword'}
                placeholder={'현재 사용중인 비밀번호를 입력해 주세요'}
                disabled={isLoading}
              />
            </FormGroup>
            <FormGroup gapsize={'s'}>
              <IntranetLibraryLabel
                isRequired={true}
                title={'새 비밀번호'}
                size={14}
              />
              <RHFPasswordField
                name={'updatePassword'}
                placeholder={'새롭게 사용할 비밀번호를 입력해 주세요'}
                disabled={isLoading}
              />
              <RHFPasswordField
                name={'updateConfirmPassword'}
                placeholder={'새롭게 사용할 비밀번호를 한 번 더 입력해 주세요'}
                disabled={isLoading}
              />
            </FormGroup>
            <IntranetLibraryBoxButton
              variant={'primary'}
              type={'submit'}
              id={formProps.id}
              disabled={isSubmitButtonDisabled}
            >
              비밀번호 변경
            </IntranetLibraryBoxButton>
          </Stack>
        </FormProvider>
      </Stack>
      <Stack>
        <SectionHeader
          title={'MS Office 365 계정'}
          subTitle={'사용 시스템'}
          tags={[
            'Finstagram Web',
            'GOMS',
            'MS O365',
            'Portal (Intranet)',
            'PLM',
            'SA (Simply Accounting)',
          ]}
        >
          <IntranetLibraryCaption sx={{ marginTop: '8px' }}>
            MS 365 비밀번호는 Microsoft 암호변경에서 변경 가능합니다.
          </IntranetLibraryCaption>
        </SectionHeader>
        <Stack sx={{ padding: '24px 20px 32px' }}>
          <IntranetLibraryLink
            href={passwordSettingLink}
            target={'_blank'}
            size={'large'}
            variant={'secondary'}
          >
            MS 365 비밀번호 변경하기
          </IntranetLibraryLink>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PasswordSetting;

const SectionHeader = ({
  title,
  subTitle,
  tags,
  children,
}: PropsWithChildren<{
  readonly title: string;
  readonly subTitle: string;
  readonly tags: string[];
}>) => {
  return (
    <Stack
      sx={{
        padding: '0 24px',
      }}
      gap={'8px'}
    >
      <Divider sx={{ marginBottom: `${24 - 8}px` }} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IntranetLibraryBody1>{title}</IntranetLibraryBody1>
        <IntranetLibraryBody2
          sx={{
            marginLeft: '8px',
          }}
        >
          {subTitle}
        </IntranetLibraryBody2>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          padding: '12px',
          borderRadius: '8px',
          background: '#F9FAFB',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '4px',
          }}
        >
          {tags.map((tag, index) => (
            <Box
              key={index}
              sx={{
                padding: '4px 8px',
                borderRadius: '4px',
                border: '#888D96',
                background: '#E7E9EC',
              }}
            >
              {tag}
            </Box>
          ))}
        </Box>
      </Box>
      {children}
    </Stack>
  );
};
