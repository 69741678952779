import {
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummaryProps,
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  Modal,
  Stack,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiTypography from '@mui/material/Typography';
import { ModalProps } from '@mui/base';
import CloseIcon from '@/public/icons/close_black.svg';
import React from 'react';

export const FormGroup = styled(Stack)<{ gapsize: 's' | 'm' }>`
  gap: ${({ gapsize }) => (gapsize === 's' ? '8px' : '12px')};
`;

export const FormConfig = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters {...props} />
))(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  margin: '80px 0 0',
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-root': {
    padding: 0,
    border: 'none',
  },
}));

export const FormConfigHeader = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(() => ({
  backgroundColor: 'transparent',
  padding: 0,
  minHeight: 'auto',
  '&.MuiAccordionSummary-root': {
    margin: 0,
    borderBottom: '1px solid #000',
    paddingBottom: '12px',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    color: '#000',
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '40px',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    padding: 0,
  },
}));

export const FormConfigHeaderTitle = styled((props: TypographyProps) => (
  <MuiTypography {...props} />
))(() => ({
  color: '#000',
  fontSize: '28px',
  fontWeight: 500,
  lineHeight: '40px',
}));

export const FormConfigBody = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))(() => ({
  margin: '40px 0',
  '&.MuiAccordionDetails-root': {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
}));

export const BoardFormModal = styled(({ children, ...props }: ModalProps) => (
  <Modal {...props} component="div">
    {children}
  </Modal>
))(() => ({
  padding: '24px 20px',
}));

export const BoardModalPaper = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => (
    <Box {...props} ref={ref}>
      {children}
    </Box>
  )),
)(() => ({
  position: 'fixed',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',

  width: 'calc(100vw - 80px)',
  minWidth: '944px',
  maxWidth: '1186px',
  height: 'calc(100vh - 80px)',
  maxHeight: 'initial',
  paddingTop: '0',

  borderRadius: '8px',
  backgroundColor: '#ffffff',
  overflowX: 'hidden',
  overflowY: 'auto',

  '& #preview-modal-board-detail-header': {
    left: 'initial',
  },
  '@media (max-width: 768px)': {
    width: '100%',
    minWidth: 'auto',
    maxWidth: '100vw',
    height: '100%',
    maxHeight: '100vh',
  },
}));

export const ModalSlide = styled((props: BoxProps) => <Box {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    transition: 'transform 0.5s',
    alignItems: 'flex-start',
  }),
);

export const Slide = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => (
    <Box {...props} ref={ref}>
      {children}
    </Box>
  )),
)(() => ({
  position: 'relative',
  flex: 'none',
  overflow: 'visible',
}));

export const ModalHeader = styled((props: BoxProps) => <Box {...props} />)(
  ({ theme }) => ({
    position: 'sticky',
    top: '0',
    zIndex: theme.zIndex.modal + 1,
    display: 'flex',
    padding: '16px 24px 8px',
    background: '#fff',
    marginBottom: '32px',
    borderRadius: '8px',
  }),
);

export const ModalHeaderDivider = styled(Box)(() => ({
  position: 'absolute',
  left: '24px',
  right: '24px',
  height: '1px',
  bottom: '0',
  background: '#000',
}));

export const ModalTitle = styled((props: TypographyProps) => (
  <Typography variant="h6" component="h2" {...props} />
))(() => ({
  color: '#000',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '32px',
}));

export const ModalCloseButton = styled((props: IconButtonProps) => (
  <Box
    sx={{
      marginLeft: 'auto',
      paddingLeft: '4px',
      display: 'flex',
      alignItems: 'end',
    }}
  >
    <IconButton
      disableRipple
      sx={{
        width: '24px',
        height: '24px',
        padding: 0,
        '&:hover': {
          backgroundColor: 'initial',
        },
      }}
      {...props}
    >
      <CloseIcon />
    </IconButton>
  </Box>
))(() => ({
  color: '#000',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '32px',
}));

export const ModalBody = styled((props: BoxProps) => <Box {...props} />)(
  () => ({
    margin: '0 auto',
    padding: '0 24px',
    width: '100%',
  }),
);

export const ModalFooter = styled((props: BoxProps) => <Box {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '8px',

    position: 'sticky',
    bottom: 0,
    zIndex: theme.zIndex.modal + 1,
    padding: '20px 24px',
    borderTop: '1px solid #E7E9EC',
    background: '#F9FAFB',
    marginTop: '24px',
  }),
);

export const AutoSavedTime = styled((props: TypographyProps) => (
  <MuiTypography {...props} />
))(() => ({
  color: '#888D96',
  textAlign: 'right',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
}));
