import React, { useState } from 'react';
import process from 'process';
import {
  Box,
  BoxProps,
  Button,
  Chip,
  Divider,
  MenuItem,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import {
  BoardDetailMenu,
  BoardDetailSubText,
  BoardHeaderSx,
} from '@/components/Board/BoardDetail/styles';
import { useCustomDialog } from '@/hooks/useCustomDialog';
import { useDeleteBoard } from '@/hooks/apiHooks';
import { PageURL } from '@/constant/pageURL';
import { copyClipBoardText } from '@/utils';
import { useReactToPrint } from 'react-to-print';
import EyeIcon from '@/public/icons/eye/eye_show_24.svg';
import CommentIcon from '@/public/icons/comment.svg';
import ReadMoreIcon from '@/public/images/icon/read_more_24.svg';
import LinkIcon from '@/public/images/icon/link_20.svg';
import PrintIcon from '@/public/images/icon/print_20.svg';
import useCustomToast from '@/hooks/useCustomToast';
import { ActionType } from '@/constant/button';
import { BoardDetailData } from '@/types/board';
import UserProfilePopper from '@/components/Popper/UserProfilePopper';
import useUserProfilePopper from '@/hooks/useUserProfilePopper';
import useCustomModal from '@/hooks/useCustomModal';
import BoardEdit from '@/components/Board/BoardEdit';
import { BoardDetailHeaderId } from '@/components/Board/BoardDetailModal/BoardDetailModal';
import classNames from 'classnames';
import { ImportantBadgeIcon } from '@/theme/overrides/CustomIcons';
import { formatFullDateString } from '@/utils/date';
import { boardWebPushCall } from '@/components/NotiTopics';

type BoardDetailHeaderProps = {
  isMobile: boolean;
  isModal: boolean;
  id?: string;
  commentCount: number;
  isCompact?: boolean;
  disabled?: boolean;
  onClickComment: () => void;
  onDeleted?: (documentId: string) => void;
  onEdited?: () => void;
  data: BoardDetailData;
} & BoxProps;

const BoardDetailHeader = (_props: BoardDetailHeaderProps) => {
  const {
    isMobile,
    isModal,
    id = BoardDetailHeaderId,
    commentCount,
    isCompact = false,
    disabled,
    onClickComment,
    onDeleted,
    onEdited,
    data,
    sx,
    ...props
  } = _props;

  const {
    subject,
    id: documentId,
    categories,
    watchCount,
    createdBy,
    config,
  } = data;
  const { isImportant, isReserved, isDeleteEnable, displayStartedAt } = config;
  const now = new Date();
  const startDate = new Date(displayStartedAt);
  const createdAt = formatFullDateString(startDate);

  const { anchorEl, onOpenPopper, onClosePopper, onClickAwayPopper } =
    useUserProfilePopper<HTMLElement>(createdBy.passportId);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const isMenuOpen = Boolean(menuAnchorEl);
  const [openSuccessLinkCopy, setOpenSuccessLinkCopy] = useState(false);
  const dialog = useCustomDialog();
  const toast = useCustomToast();
  const print = useReactToPrint({
    content: () => document.getElementById('board-content'),
  });

  const {
    isOpen: isBoardEditModalOpen,
    openModal: openBoardEditModal,
    closeModal,
  } = useCustomModal();

  const closeMenu = () => setMenuAnchorEl(null);
  const clickMenu = async (type: ActionType) => {
    if (disabled) return;

    switch (type) {
      case ActionType.LINK_COPY: {
        const resultCopy = await copyClipBoardText(
          `${process.env.NEXT_PUBLIC_ORIGIN_URL}${PageURL.BOARD_DETAIL(
            documentId,
          )}`,
        );
        if (resultCopy) setOpenSuccessLinkCopy(true);
        break;
      }
      case ActionType.PRINT:
        print();
        break;
      case ActionType.EDIT:
        openBoardEditModal();
        break;
      case ActionType.DELETE:
        dialog.show({
          message: '게시글을 삭제하시겠습니까?',
          dismissButtonTitle: '취소',
          confirmButtonTitle: '삭제',
          onConfirm: async () => {
            const result = await useDeleteBoard(documentId);
            if (result === true) {
              toast.show({
                severity: 'success',
                message: '게시글이 삭제되었습니다.',
              });
              if (onDeleted) onDeleted(documentId);
              boardWebPushCall();
            } else {
              toast.showError({
                message: result || '삭제 실패. 새로고침 후 다시 시도해 주세요.',
              });
            }
          },
        });
        break;
    }
    // 메뉴 닫기
    setMenuAnchorEl(null);
  };

  return (
    <Box
      id={id}
      component={'header'}
      className={classNames({ isCompact, isMobile, isModal })}
      sx={{ ...BoardHeaderSx, ...sx }}
      {...props}
    >
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        rowGap={'4px'}
        columnGap={'8px'}
        className={'board-category'}
      >
        {isReserved && startDate > now && (
          <Chip
            key={`category_isReserved`}
            variant={'outlined'}
            color={'primary'}
            label={'예약게시글'}
          />
        )}
        {isImportant && (
          <Chip
            key={`category_isImportant`}
            variant={'filled'}
            color={'primary'}
            label={
              <Stack alignItems={'center'} direction={'row'}>
                <ImportantBadgeIcon style={{ marginLeft: '-2px' }} />
                {'중요'}
              </Stack>
            }
          />
        )}
        {categories.map((item, index) => (
          <Chip
            key={`category${item.id}_${index}`}
            variant={'outlined'}
            color={'default'}
            label={item.name}
          />
        ))}
      </Stack>
      <Typography component={'h1'} className={'board-title'}>
        {subject}
      </Typography>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        marginTop={'12px'}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={'12px'}
          divider={
            <Divider
              orientation={'vertical'}
              variant={'middle'}
              flexItem
              sx={{
                display: isMobile ? 'none' : 'inline-block',
                height: '16px',
                alignSelf: 'auto',
              }}
            />
          }
        >
          <Typography
            fontSize={isMobile ? '12px' : '16px'}
            color={'black'}
            sx={{
              cursor: 'pointer',
              span: {
                display: 'inline-block',
                marginLeft: '8px',
                color: '#888D96',
              },
            }}
            onClick={onOpenPopper}
          >
            {createdBy.name} {createdBy.positionName}
            <span>{createdBy.departmentName}</span>
          </Typography>
          <BoardDetailSubText className={'board-createAt'}>
            {createdAt}
          </BoardDetailSubText>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={'8px'}>
          <Stack direction={'row'} alignItems={'center'} spacing={'2px'}>
            <EyeIcon width={'24px'} height={'24px'} />
            <BoardDetailSubText fontSize={isMobile ? '12px' : '14px'}>
              {watchCount.toLocaleString()}
            </BoardDetailSubText>
          </Stack>
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={'2px'}
            onClick={disabled ? undefined : onClickComment}
            sx={{ cursor: disabled ? 'initial' : 'pointer' }}
          >
            <CommentIcon width={'24px'} height={'24px'} />
            <BoardDetailSubText fontSize={isMobile ? '12px' : '14px'}>
              {commentCount.toLocaleString()}
            </BoardDetailSubText>
          </Stack>
          <Button
            id={'menu-button'}
            aria-controls={isMenuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? 'true' : undefined}
            disabled={disabled}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setMenuAnchorEl(event.currentTarget);
            }}
            sx={{ padding: 0, minWidth: '24px' }}
          >
            <ReadMoreIcon />
          </Button>
          <BoardDetailMenu
            id={'menu'}
            anchorEl={menuAnchorEl}
            open={disabled ? false : isMenuOpen}
            onClose={closeMenu}
            MenuListProps={{ 'aria-labelledby': 'menu-button' }}
          >
            <MenuItem
              data-menu-type={ActionType.LINK_COPY}
              onClick={() => clickMenu(ActionType.LINK_COPY)}
            >
              링크 복사
              <LinkIcon />
            </MenuItem>
            <MenuItem
              data-menu-type={ActionType.PRINT}
              onClick={() => clickMenu(ActionType.PRINT)}
            >
              인쇄
              <PrintIcon />
            </MenuItem>
            <MenuItem
              data-menu-type={ActionType.EDIT}
              onClick={() => clickMenu(ActionType.EDIT)}
              sx={{ display: isDeleteEnable ? undefined : 'none' }}
            >
              수정
            </MenuItem>
            <MenuItem
              data-menu-type={ActionType.DELETE}
              onClick={() => clickMenu(ActionType.DELETE)}
              sx={{ display: isDeleteEnable ? undefined : 'none' }}
            >
              삭제
            </MenuItem>
          </BoardDetailMenu>
        </Stack>
      </Stack>

      <UserProfilePopper
        anchorEl={anchorEl}
        passportId={createdBy.passportId}
        onClose={onClosePopper}
        onClickAway={onClickAwayPopper}
      />

      <Snackbar
        open={openSuccessLinkCopy}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setOpenSuccessLinkCopy(false)}
        message={'링크가 복사되었습니다.'}
      />
      <BoardEdit
        isModalOpen={isBoardEditModalOpen}
        closeModal={closeModal}
        documentId={documentId}
        onWriteComplete={onEdited}
      />
    </Box>
  );
};

export default BoardDetailHeader;
