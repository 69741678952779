import React, { useEffect, useRef, useState } from 'react';
import { useNotiTokens, useNotiTopics, useSetToken } from '@/hooks/apiHooks';
import { FIREBASE_TOKEN } from '@/const/tokens';
import { fetchApi } from '@/utils/api/customAxios';
import { getCookie, setCookie } from '@/utils/tokens';
import firebaseApp, { requestPermission } from '@/utils/firebase/initialize';
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from '@firebase/messaging';
import process from 'process';
import { useRecoilState } from 'recoil';
import { notiState, socialState } from '@/recoil/atom';
import { getNowFormat } from '@/utils/format';
import { NotificationData } from '@/types/api';

const listSize = 50;
const TOPIC_NAME = 'refresh_social';

export default function NotiTopics() {
  const [state, setState] = useRecoilState(socialState);
  const [noticeList, setNoticeList] = useRecoilState(notiState);
  const { data: topicsData } = useNotiTopics(listSize);
  const { data: tokensData } = useNotiTokens(listSize);

  // 이미 구독 처리가 되었는지 여부를 저장하는 ref
  const subscribedRef = useRef(false);
  const registerRef = useRef(false);

  useEffect(() => {
    if (registerRef.current) return;

    const boardApiRefresh = (payload: MessagePayload) => {
      if (payload.data?.refreshSection === 'social') {
        setState({
          ...state,
          currentDate: getNowFormat('yyyy-MM-dd HH:mm:ss'),
        });
      }
    };

    if (typeof navigator !== 'undefined') {
      if ('serviceWorker' in navigator) {
        requestPermission().then((e) => {
          const messaging = getMessaging(firebaseApp);

          //서비스 워커에서 백그라운드 이벤트 전달 수신
          navigator.serviceWorker.addEventListener('message', (event) => {
            if (
              event.data &&
              event.data.type === 'FIREBASE_BACKGROUND_MESSAGING'
            ) {
              boardApiRefresh(event.data.payload);
            }
          });

          getToken(messaging, {
            vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
          })
            .then((token) => {
              useSetToken(token).then((_) => setCookie(FIREBASE_TOKEN, token));
            })
            .catch((e) => console.error(e));
          onMessage(messaging, (payload) => {
            if (payload.data?.refreshSection === 'social') {
              boardApiRefresh(payload);
            } else {
              const newNotice: NotificationData = {
                title: payload.notification?.title || '',
                body: payload.notification?.body || '',
                link: payload.fcmOptions?.link || '',
                messageId: payload.messageId,
              };

              setNoticeList({
                data: [newNotice, ...noticeList.data],
              });
            }
          });
        });
        registerRef.current = true;
      }
    }
  }, []);

  useEffect(() => {
    // FIREBASE_TOKEN이 없거나, 이미 구독을 진행했다면 더 이상 실행하지 않음
    if (!getCookie(FIREBASE_TOKEN) || subscribedRef.current) return;

    // topicsData와 tokensData가 존재하고 데이터가 있을 때만 실행
    if (topicsData?.length && tokensData?.length) {
      // FIREBASE_TOKEN 쿠키와 일치하는 토큰 객체 찾기
      const tokenObj = tokensData.find(
        (tokenItem) => getCookie(FIREBASE_TOKEN) === tokenItem.token,
      );
      if (!tokenObj?.id) {
        console.warn('No matching browser token found.');
        return;
      }

      const subscribeToTopics = async () => {
        try {
          await Promise.all(
            topicsData.map((topic) => {
              if (topic.topic === TOPIC_NAME) {
                return fetchApi({
                  method: 'POST',
                  url: '/api/v1/notificationCenter/subscribe/topic',
                  baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
                  data: {
                    tokenId: tokenObj.id,
                    topicId: topic.id,
                  },
                });
              }
              // 조건에 맞지 않으면 resolved Promise 반환
              return Promise.resolve();
            }),
          );
          // 구독 작업이 완료되었으므로 플래그 업데이트
          subscribedRef.current = true;
          console.log('Subscription complete.');
        } catch (error) {
          console.error('Error during subscription:', error);
        }
      };

      subscribeToTopics();
    }
  }, [topicsData, tokensData, getCookie(FIREBASE_TOKEN)]);

  return <></>;
}

export function boardWebPushCall() {
  fetchApi({
    method: 'POST',
    url: '/api/v1/webPush/topic/sendByTemplate',
    baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
    data: {
      templateKey: 'topic_refresh',
      templateData: {
        data: {
          refreshSection: 'social',
        },
      },
      topic: TOPIC_NAME,
      sendBy: 'direct',
    },
  });
}
