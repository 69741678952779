import { Controller, useFormContext } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { IconButton, InputAdornment, Stack, Typography } from '@mui/material';

export type RHFTextFieldProps = TextFieldProps & {
  readonly name: string;
  readonly maxLength?: number;
  readonly onClear?: () => void;
};

export const ErrorHelperTextStyle = {
  width: '100%',
  justifyContent: 'space-between',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  padding: 0,
  margin: '8px 50% 0 0',
  color: '#F55060',
};

export default function RHFTextField({
  name,
  helperText,
  type,
  maxLength,
  size,
  sx,
  onClear,
  ...other
}: RHFTextFieldProps) {
  const { control, clearErrors } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack>
          <TextField
            {...field}
            fullWidth
            type={type}
            value={type === 'number' && field.value === 0 ? '' : field.value}
            onChange={(event) => {
              if (type === 'number') {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
            }}
            error={!!error}
            helperText={error ? error?.message : helperText}
            InputProps={{
              endAdornment: field.value && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      field.onChange('');
                      clearErrors(name);
                      onClear?.();
                    }}
                  >
                    <img
                      src={'/images/icon/close_gray.svg'}
                      width={16}
                      height={16}
                      alt={'close icon'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            FormHelperTextProps={{
              sx: {
                ...ErrorHelperTextStyle,
                color: error ? '#F55060' : '#888D96',
              },
            }}
            sx={{
              '& .MuiInputBase-root': {
                height: '48px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
                borderColor: '#000',
              },
              ...sx,
            }}
            {...other}
          />
          {maxLength !== undefined && (
            <Typography
              align={'right'}
              sx={{
                mt: error || helperText ? '-18px' : '8px',
                fontSize: '14px',
              }}
            >
              {field.value?.length ?? 0}{' '}
              <Typography
                component={'span'}
                variant={'inherit'}
                color={'#D4D7DB'}
              >
                / {maxLength}
              </Typography>
            </Typography>
          )}
        </Stack>
      )}
    />
  );
}
