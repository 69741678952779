import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  DisplayDateBody,
  DisplayDateHeader,
  DisplayDateWrapper,
  ErrorMessage,
  ErrorText,
  GrayText,
  GuideText,
  ImageUploadFormWrapper,
  ImageUploadLabel,
  ImageUploadWrapper,
  OptionWrapper,
  PopupBody,
  PopupContent,
  PopupFooter,
  PopupHeader,
  PopupHeaderTitle,
  PreviewBody,
  PreviewContentSummary,
  PreviewHeader,
  PreviewImg,
  PreviewTitle,
  PreviewWrapper,
  VisuallyHiddenInput,
} from './styled';
import IntranetLibraryLabel from '@/components/@IntranetLibrary/Label';
import { Box, Button, IconButton, Link, Modal } from '@mui/material';
import { PageURL } from '@/constant/pageURL';
import RHFDateTimePicker from '@/components/HookForm/RHFDateTimePicker';
import {
  base64ToFile,
  getMimeTypeByExtension,
  uploadImage,
  validateFile,
} from '@/components/file-thumbnail/utils';
import { useCustomDialog } from '@/hooks/useCustomDialog';
import Backdrop from '@mui/material/Backdrop';
import ImageCropEditor from '@/components/ImageCropEditor';
import useImageCropEditorProps from '@/components/ImageCropEditor/useImageCropEditorProps';
import IntranetLibraryBoxButton from '@/components/@IntranetLibrary/BoxButton';
import { uuid4 } from '@sentry/utils';
import RHFRadio from '@/components/HookForm/RHFRadio';
import { BoardFormProps } from '@/components/Board/BoardForm';
import useCustomToast from '@/hooks/useCustomToast';
import { BooleanOption } from '@/components/Board/BoardForm/@hooks/useBoardForm';
import IntranetLibraryRadio from '@/components/@IntranetLibrary/Radio';

type optionProps =
  | {
      readonly thumbnailOption: 'none';
    }
  | {
      readonly thumbnailOption: 'required' | 'optional';
      readonly thumbnail: {
        readonly optimalSize: { width: number; height: number };
        readonly minimumSize: { width: number; height: number };
        readonly preview: {
          readonly imageWidth: number;
          readonly isTitleCenter: boolean;
          readonly hasContentSummery: boolean;
          readonly hasBackgroundColor: boolean;
        };
      };
    };

export function stripHTML(html: string): string {
  return html.replace(/<\/?[^>]+(>|$)/g, '');
}

const DisplaySections: React.FC<{
  options: BoardFormProps['displaySectionOptions'];
}> = ({ options }) => {
  const dialog = useCustomDialog();
  const { showError } = useCustomToast();

  const { control, setValue, getValues, reset } = useFormContext();
  const subject = getValues('subject');
  const content = getValues('content');
  const thumbnailFile = getValues('thumbnailFile');
  const useThumbnail = getValues('useThumbnail') === BooleanOption.TRUE;
  const displaySections = getValues('displaySections');
  const useThumbnailDisplayTime =
    getValues('useThumbnailDisplayTime') === BooleanOption.TRUE;
  const thumbnailDisplayStartedAt = getValues('thumbnailDisplayStartedAt');
  const thumbnailDisplayEndAt = getValues('thumbnailDisplayEndAt');

  const [isUploading, setIsUploading] = useState(false);
  const [showImageCropEditor, setShowImageCropEditor] = useState(false);

  const [selectedFile, setSelectedFile] = useState<File>();
  const [imageSrc, setImageSrc] = useState('');
  const [previewImageSrc, setPreviewImageSrc] = useState('');

  const optionProps: optionProps | undefined = useMemo(() => {
    switch (displaySections) {
      case 'bannerSection': {
        return {
          thumbnailOption: 'required',
          thumbnail: {
            optimalSize: { width: 2410, height: 780 },
            minimumSize: { width: 1205, height: 390 },
            preview: {
              imageWidth: 682,
              isTitleCenter: true,
              hasContentSummery: false,
              hasBackgroundColor: false,
            },
          },
        };
      }
      case 'rightFixSection': {
        return {
          thumbnailOption: 'required',
          thumbnail: {
            optimalSize: { width: 1200, height: 1200 },
            minimumSize: { width: 600, height: 600 },
            preview: {
              imageWidth: 324,
              isTitleCenter: false,
              hasContentSummery: false,
              hasBackgroundColor: true,
            },
          },
        };
      }
      case 'rightSection': {
        return {
          thumbnailOption: 'optional',
          thumbnail: {
            optimalSize: { width: 1920, height: 1080 },
            minimumSize: { width: 960, height: 540 },
            preview: {
              imageWidth: 322,
              isTitleCenter: false,
              hasContentSummery: true,
              hasBackgroundColor: true,
            },
          },
        };
      }
      case 'importantSection':
      case 'interviewSection': {
        return {
          thumbnailOption: 'none',
        };
      }
    }
  }, [displaySections]);

  const handleImageOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file || !optionProps || optionProps.thumbnailOption === 'none') {
      return;
    }

    try {
      validateFile([file], {
        isOnlyImage: true,
        maxLength: 1,
      });
    } catch (e) {
      if (e instanceof Error) {
        return showError({ message: e.message });
      } else {
        return showError({
          message: '이미지 선택에 에러가 발생했어요. 다시 시도해주세요',
        });
      }
    }

    const img = new Image();
    const imageSrc = URL.createObjectURL(file);

    img.src = imageSrc;
    img.onload = () => {
      event.target.value = '';
      setImageSrc(imageSrc);
      setShowImageCropEditor(true);
      setSelectedFile(file);
    };

    img.onerror = () => {
      event.target.value = '';
      showError({
        message: '이미지를 불러올 수 없습니다.',
      });
    };
  };

  const closeImageCropEditor = useCallback(() => {
    setImageSrc('');
    setShowImageCropEditor(false);
  }, []);

  const resetDateTime = useCallback(() => {
    reset((prevValue) => ({
      ...prevValue,
      useThumbnailDisplayTime: BooleanOption.FALSE,
      thumbnailDisplayStartedAt: null,
      thumbnailDisplayEndAt: null,
    }));
  }, [reset]);

  const handleOnError = useCallback(() => {
    showError({
      message: '이미지를 불러오는데 실패했습니다',
      onClose: () => {
        setImageSrc('');
      },
    });
  }, []);

  const handleOptionChange: (...event: any[]) => void = (event, newValue) => {
    const name = 'displaySections';
    const currentValue = getValues(name);
    const hasValue = Boolean(
      thumbnailFile ||
        (useThumbnailDisplayTime &&
          (thumbnailDisplayStartedAt || thumbnailDisplayEndAt)),
    );

    const setNewValue = () => {
      closeImageCropEditor();
      setIsUploading(false);
      setPreviewImageSrc('');
      setImageSrc('');
      setSelectedFile(undefined);

      const useThumbnail =
        newValue === 'none' ||
        newValue === 'interviewSection' ||
        newValue === 'importantSection' ||
        newValue === 'rightSection'
          ? BooleanOption.FALSE
          : BooleanOption.TRUE;

      reset((prevValue) => ({
        ...prevValue,
        displaySections: newValue,
        useThumbnail,
        thumbnailFile: null,
        useThumbnailDisplayTime: BooleanOption.FALSE,
        thumbnailDisplayStartedAt: null,
        thumbnailDisplayEndAt: null,
      }));
    };

    if (hasValue && currentValue !== newValue) {
      dialog.show({
        message: (
          <>
            위치를 바꾸면 설정한 내용이 초기화 돼요.
            <br />
            위치를 바꾸시겠어요?
          </>
        ),
        confirmButtonTitle: '바꾸기',
        onConfirm: setNewValue,
        dismissButtonTitle: '취소',
        onDismiss: () => setValue(name, currentValue),
      });
      return;
    }

    setNewValue();
  };

  const minWidth =
    optionProps?.thumbnailOption === 'none'
      ? 0
      : optionProps?.thumbnail?.minimumSize?.width;
  const minHeight =
    optionProps?.thumbnailOption === 'none'
      ? 0
      : optionProps?.thumbnail?.minimumSize?.height;
  const optimalWidth =
    optionProps?.thumbnailOption === 'none'
      ? 0
      : optionProps?.thumbnail?.optimalSize?.width ?? 0;
  const optimalHeight =
    optionProps?.thumbnailOption === 'none'
      ? 1
      : optionProps?.thumbnail?.optimalSize?.height ?? 1;

  const imageCropEditorProps = useImageCropEditorProps({
    src: imageSrc,
    minWidth,
    minHeight,
    aspect: optionProps ? optimalWidth / optimalHeight : undefined,
    cropCanvasSize: 446,
    handleOnError,
  });

  useEffect(() => {
    if (thumbnailFile?.preview) {
      setPreviewImageSrc(thumbnailFile.preview);
    }
  }, [thumbnailFile?.preview]);

  return (
    <>
      <Controller
        name={'displaySections'}
        control={control}
        render={({ field }) => (
          <>
            <IntranetLibraryLabel
              isRequired={false}
              title={'홈 화면에 보여줄 위치를 선택해 주세요.'}
              description={
                <>
                  ‘미노출하기’를 선택하면 <BoardLink /> 화면에서만 볼 수 있어요.
                </>
              }
              size={16}
            />
            <IntranetLibraryRadio
              {...field}
              row
              options={options}
              onChange={handleOptionChange}
            />
          </>
        )}
      />
      {optionProps && (
        <OptionWrapper>
          {optionProps.thumbnailOption !== 'none' && (
            <>
              <Modal open={showImageCropEditor} onClose={closeImageCropEditor}>
                <PopupContent onClick={(e) => e.stopPropagation()}>
                  <PopupHeader>
                    <PopupHeaderTitle>이미지 비율 조정</PopupHeaderTitle>
                    <IconButton
                      disableRipple
                      sx={{
                        marginLeft: 'auto',
                        padding: 0,
                      }}
                      onClick={closeImageCropEditor}
                    >
                      <CancelIcon />
                    </IconButton>
                  </PopupHeader>
                  <PopupBody>
                    <ImageCropEditor {...imageCropEditorProps} />
                    <GuideText>
                      선택된 사이즈:{' '}
                      {imageCropEditorProps.selectedImageSize.width}x
                      {imageCropEditorProps.selectedImageSize.height}
                    </GuideText>
                    <ErrorMessage>
                      {imageCropEditorProps.isMinimumReached && (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <circle
                              cx="8"
                              cy="8"
                              r="5"
                              stroke="#F55060"
                              strokeWidth="0.8"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <rect
                              x="8"
                              y="10.2222"
                              width="0.00698242"
                              height="0.00698242"
                              stroke="#F55060"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8 8L8 5.77778"
                              stroke="#F55060"
                              strokeWidth="0.8"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <ErrorText>
                            이미지 사이즈가 {minWidth}x{minHeight} 이하인 경우,
                            썸네일이 선명하지 않을 수 있습니다.
                          </ErrorText>
                        </>
                      )}
                    </ErrorMessage>
                  </PopupBody>
                  <PopupFooter>
                    <IntranetLibraryBoxButton
                      sx={{
                        width: '304px',
                      }}
                      variant={'two'}
                      left={{
                        onClick: () => {
                          closeImageCropEditor();
                        },
                        children: '취소',
                      }}
                      right={{
                        onClick: async () => {
                          closeImageCropEditor();

                          try {
                            if (!selectedFile) {
                              return;
                            }

                            setIsUploading(true);
                            const base64Image =
                              imageCropEditorProps.getCroppedImage();
                            const file = base64ToFile(
                              base64Image,
                              selectedFile.name,
                            );

                            const uploadedFile = await uploadImage(file);
                            setValue(
                              'thumbnailFile',
                              {
                                key: uuid4(),
                                name: uploadedFile.name,
                                path: uploadedFile.url,
                                preview: uploadedFile.url,
                                status: 'new',
                                type: getMimeTypeByExtension(uploadedFile.name),
                                isUploaded: true,
                              },
                              { shouldValidate: true },
                            );
                            setSelectedFile(undefined);
                          } catch (e) {
                            dialog.show({
                              message: '이미지 업로드에 실패했습니다',
                            });
                          } finally {
                            setIsUploading(false);
                          }
                        },
                        children: '확인',
                      }}
                    />
                  </PopupFooter>
                </PopupContent>
              </Modal>
              <ImageUploadWrapper>
                <div>
                  <ImageUploadLabel>
                    썸네일 이미지를 사용하실건가요?
                  </ImageUploadLabel>
                  <RHFRadio
                    name={'useThumbnail'}
                    options={[
                      { value: BooleanOption.TRUE, label: '사용 하기' },
                      { value: BooleanOption.FALSE, label: '사용 안하기' },
                    ]}
                  />
                </div>
                {useThumbnail && (
                  <>
                    <div>
                      <ImageUploadLabel>
                        이미지를 업로드해 주세요. (최적: {optimalWidth}x
                        {optimalHeight} / 최소: {minWidth}x{minHeight})
                      </ImageUploadLabel>
                      <ImageUploadFormWrapper>
                        <IntranetLibraryBoxButton
                          component={'label'}
                          variant={'secondary'}
                          tabIndex={-1}
                          loading={isUploading}
                        >
                          이미지 업로드
                          <VisuallyHiddenInput
                            type="file"
                            hidden
                            accept={'image/*'}
                            onChange={handleImageOnChange}
                            disabled={isUploading}
                          />
                        </IntranetLibraryBoxButton>
                        <GrayText>{thumbnailFile?.name ?? ''}</GrayText>
                      </ImageUploadFormWrapper>
                    </div>
                    {optionProps.thumbnail &&
                      (previewImageSrc ||
                        optionProps.thumbnailOption === 'optional') && (
                        <PreviewWrapper>
                          <PreviewHeader>
                            <GrayText>미리보기</GrayText>
                          </PreviewHeader>
                          <PreviewBody
                            sx={{
                              background: optionProps.thumbnail.preview
                                .hasBackgroundColor
                                ? '#fff'
                                : 'transparent',
                              width: `${optionProps.thumbnail.preview.imageWidth}px`,
                            }}
                          >
                            {previewImageSrc && (
                              <PreviewImg
                                component="img"
                                src={previewImageSrc}
                                alt={'선택한 이미지 미리보기'}
                              />
                            )}
                            <PreviewTitle
                              sx={{
                                textAlign: optionProps.thumbnail.preview
                                  .isTitleCenter
                                  ? 'center'
                                  : 'initial',
                                color: subject ? '#000' : '#888D96',
                                fontWeight: subject ? 600 : 400,
                              }}
                            >
                              {subject || '제목'}
                            </PreviewTitle>
                            {optionProps.thumbnail.preview
                              .hasContentSummery && (
                              <PreviewContentSummary>
                                {stripHTML(content)}
                              </PreviewContentSummary>
                            )}
                          </PreviewBody>
                        </PreviewWrapper>
                      )}
                  </>
                )}
              </ImageUploadWrapper>
            </>
          )}
          <DisplayDateWrapper>
            <DisplayDateHeader>
              <IntranetLibraryLabel
                isRequired={false}
                title="홈 화면에 특정 기간에만 보이도록 할까요?"
                description={
                  <>
                    설정하지 않으면 계속 보이고, 설정한 기간이 끝나면{'  '}
                    <BoardLink /> 화면에서만 볼 수 있어요.
                  </>
                }
                size={14}
              />
              <Button
                disableRipple
                disabled={isUploading}
                endIcon={<RefreshIcon />}
                onClick={resetDateTime}
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: '#000',
                  '& .MuiButton-endIcon': {
                    marginLeft: 0,
                  },
                  '&:hover, &:focus': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  },
                }}
              >
                초기화
              </Button>
            </DisplayDateHeader>
            <DisplayDateBody>
              <RHFRadio
                name={'useThumbnailDisplayTime'}
                options={[
                  { value: BooleanOption.FALSE, label: '항상 보여주기' },
                  {
                    value: BooleanOption.TRUE,
                    label: '특정 기간에만 보여주기',
                  },
                ]}
              />
            </DisplayDateBody>
            {useThumbnailDisplayTime && (
              <DisplayDateBody>
                <RHFDateTimePicker
                  name={'thumbnailDisplayStartedAt'}
                  triggerField={
                    thumbnailDisplayEndAt ? 'thumbnailDisplayEndAt' : undefined
                  }
                />
                <Box sx={{ height: '44px', lineHeight: '44px' }}>-</Box>
                <RHFDateTimePicker
                  name={'thumbnailDisplayEndAt'}
                  triggerField={
                    thumbnailDisplayStartedAt
                      ? 'thumbnailDisplayStartedAt'
                      : undefined
                  }
                />
              </DisplayDateBody>
            )}
          </DisplayDateWrapper>
        </OptionWrapper>
      )}
    </>
  );
};

export default DisplaySections;

const RefreshIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 3.25C10.6234 3.25 12.75 5.37665 12.75 8C12.75 10.6234 10.6234 12.75 8 12.75C5.37665 12.75 3.25 10.6234 3.25 8C3.25 6.50783 3.93805 5.17637 5.01418 4.30556"
      stroke="black"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.25 4.04199H5.36111V6.1531"
      stroke="black"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CancelIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 6.5L6.5 17.5"
      stroke="black"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 6.5L17.5 17.5"
      stroke="black"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BoardLink = () => (
  <Link
    href={PageURL.SOCIAL}
    target="_blank"
    sx={{
      textDecoration: 'none',
    }}
  >
    게시판
  </Link>
);
