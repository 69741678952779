import { format } from 'date-fns';
import { ko } from 'date-fns/locale';

export const formatNumberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const dateFormat = (date: string) => {
  return `${format(new Date(date), 'MM월dd일')}(${format(
    new Date(date),
    'EEE',
    {
      locale: ko,
    },
  )})`;
};

export const yearDayFormat = (date: string) => {
  return `${format(new Date(date), 'yyyy.MM.dd')} (${format(
    new Date(date),
    'EEE',
    {
      locale: ko,
    },
  )})`;
};

export const getNowFormat = (formatStr: string) => {
  return `${format(new Date(), formatStr)}`;
};

export const getDateFormat = (date: string, formatStr: string) => {
  return `${format(new Date(date), formatStr)}`;
};

export const yearDateFormat = (date: string) => {
  return `${format(new Date(date), 'yyy-MM-dd')}`;
};

export const getPhoneNumberFormat = (input: string): string => {
  const digits = input.replace(/\D/g, '');

  if (digits.length <= 3) {
    return digits;
  } else if (digits.length <= 6) {
    return `${digits.slice(0, 3)}-${digits.slice(3)}`;
  } else if (digits.length === 10) {
    return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
  } else if (digits.length === 11) {
    return `${digits.slice(0, 3)}-${digits.slice(3, 7)}-${digits.slice(7)}`;
  }

  return `${digits.slice(0, 3)}-${digits.slice(3, 7)}-${digits.slice(7, 11)}`;
};
