import { BoardDetailData, BoardViewType } from '@/types/board';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Divider, Stack, SxProps, Tab } from '@mui/material';
import { Theme } from '@mui/material/styles';
import BoardDetailLikes, {
  BoardDetailLikesRef,
} from '@/components/Board/BoardDetail/Feedback/BoardDetailLikes';
import { scrollToElement } from '@/utils';
import { changeLikeBoard } from '@/hooks/apiHooks';
import BoardDetailHeader from '@/components/Board/BoardDetail/Content/BoardDetailHeader';
import BoardDetailContent from '@/components/Board/BoardDetail/Content/BoardDetailContent';
import ImageSwiper from '@/components/Swiper/ImageSwiper';
import BoardLikeButton from '@/components/Button/BoardLikeButton';
import { TabContext, TabPanel } from '@mui/lab';
import {
  BoardReactionTabList,
  FileAttachmentWrapper,
} from '@/components/Board/BoardDetail/styles';
import BoardDetailComment from '@/components/Board/BoardDetail/Feedback/BoardDetailComment';
import AttachmentFile from 'components/AttachmentFile';
import { RemoteFile } from '@/components/file-thumbnail/utils';
import { useCustomDialog } from '@/hooks/useCustomDialog';
import { formatFullDateString } from '@/utils/date';
import { LayoutType } from '@/constant/layout';
import useLayoutSize from '@/hooks/useLayoutSize';
import Head from 'next/head';
import useDetectScroll from '@smakss/react-scroll-direction';
import { ActionType } from '@/constant/button';
import { BoardDetailHeaderId } from '@/components/Board/BoardDetailModal/BoardDetailModal';
import classNames from 'classnames';

export enum ReactionTab {
  COMMENT = 'comment',
  LIKE = 'like',
}

export type BoardDetailProps = {
  layoutType?: LayoutType;
  isModal?: boolean;
  scrollRef?: React.RefObject<HTMLDivElement>;
  data: BoardDetailData;
  isRowDirection?: boolean;
  headerId?: string;
  disabled?: boolean;
  onDeleted?: (documentId: string) => void;
  onEdited?: () => void;
  onChange?: (id: string, changes: Record<string, any>) => void;
  mutate?: any;
  sx?: SxProps<Theme>;
};

const BoardDetail = (props: BoardDetailProps) => {
  const {
    layoutType = LayoutType.DEFAULT,
    isModal = true,
    scrollRef,
    data,
    isRowDirection = false,
    headerId = BoardDetailHeaderId,
    disabled,
    onDeleted,
    onEdited,
    onChange,
    mutate,
    sx,
  } = props;

  const { isMobile } = useLayoutSize(layoutType);
  const dialog = useCustomDialog();
  const isLoadingSet = new Set<ActionType>();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const [commentCount, setCommentCount] = useState(0);
  const [isLike, setIsLike] = useState(false);
  const [selectedTab, setSelectedTab] = useState<ReactionTab>(
    ReactionTab.COMMENT,
  );
  const boardDetailRef = useRef<HTMLElement | null>(null);
  const boardReactionWrapRef = useRef<HTMLElement | null>(null);
  const boardDetailLikesRef = useRef<BoardDetailLikesRef | null>(null);

  const [scrollTarget, setScrollTarget] = useState<HTMLDivElement>();
  const { scrollDir, scrollPosition } = useDetectScroll({
    target: scrollTarget,
  });
  const [isScrollDown, setIsScrollDown] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (!data) return;
    setIsLike(data.feedback.isLike);
  }, [data]);

  useEffect(() => {
    const headerEl = document.getElementById(headerId);
    if (headerEl) setHeaderHeight(headerEl.clientHeight);
  }, [isRowDirection]);

  useEffect(() => {
    if (scrollRef?.current) setScrollTarget(scrollRef.current);
  }, [scrollRef]);

  useEffect(() => {
    setIsScrollDown(scrollPosition.top > 0);
  }, [scrollDir, scrollPosition]);

  const scrollToCommentSection = useCallback(() => {
    setSelectedTab(ReactionTab.COMMENT);
    if (!isRowDirection) {
      const element = boardReactionWrapRef.current;

      if (!element) return;

      if (!isModal) {
        let offset = 24 + 28; // 리액션 섹션 marginTop + 탭 크기
        if (boardDetailRef.current) {
          const style = getComputedStyle(boardDetailRef.current);
          offset += Number(
            style
              .getPropertyValue('--board-detail-offset-top')
              .replace('px', ''),
          );
          offset += Number(
            style
              .getPropertyValue('--board-detail-padding-top')
              .replace('px', ''),
          );
        }
        scrollToElement(element, offset);
      } else {
        const scrollTarget =
          document.getElementsByClassName('modal-content')[0];
        if (scrollTarget) {
          let offset = 28; // 탭 크기
          if (boardDetailRef.current) {
            const style = getComputedStyle(boardDetailRef.current);
            offset += Number(
              style.getPropertyValue('--modal-padding').replace('px', ''),
            );
          }
          scrollTarget.scrollTo({
            top: element.offsetTop - offset,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [isRowDirection, isModal]);

  const toggleBoardLike = async () => {
    if (isLoadingSet.has(ActionType.LIKE)) return;

    isLoadingSet.add(ActionType.LIKE);
    const result = await changeLikeBoard(data.id, !isLike);
    if (result) {
      setIsLike(!isLike);
      boardDetailLikesRef.current?.refresh();
      const likeCount = (data.feedback.likesCount += !isLike ? 1 : -1);
      onChange?.(data.id, { isLike: !isLike, likeCount });
      mutate?.(
        { ...data, feedback: { ...data.feedback, isLike: !isLike } },
        false,
      );
    }

    isLoadingSet.delete(ActionType.LIKE);
  };

  const handleComment = (isComment: boolean, commentCount: number) => {
    onChange?.(data.id, { isComment: isComment, commentCount: commentCount });
  };

  const onDownloadAttachmentFile = async (file: RemoteFile) => {
    try {
      setIsLoadingDownload(true);
      const response = await fetch(file.link);

      if (!response.ok) {
        throw new Error('파일을 다운로드할 수 없습니다.');
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      dialog.show({
        message: `${error}` || '파일을 다운로드 하는데 실패했습니다',
      });
    } finally {
      setIsLoadingDownload(false);
    }
  };

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <Box
        ref={boardDetailRef}
        component={isModal ? 'div' : 'main'}
        className={classNames({ isRowDirection, isModal, isMobile })}
        sx={{
          width: '100%',
          position: 'relative',
          '.board-reaction-wrap': { paddingTop: '24px' },
          '#board-content': { paddingTop: `${headerHeight}px` },
          '&.isRowDirection': {
            '--board-detail-comment-width': '420px',
            header: {
              width:
                'calc(min(var(--page-max-width, 100%), 100%) - var(--board-detail-comment-width, 0px) - (var(--board-detail-padding-X, var(--modal-padding, 0px)) * 2))',
              top: 'calc(var(--board-detail-offset-top, 0px) + 1px)',
              left: 'max(calc((100% - var(--page-max-width, 100%)) / 2), var(--board-detail-padding-X, var(--modal-padding, 0px)))',
              transform: 'none',
              paddingRight: '16px',
              borderRight: '1px solid #E7E9EC',
            },
            '.board-content-wrap': { flexDirection: 'row', gap: 0 },
            '#board-content': { minWidth: 0, flex: 1, paddingRight: '16px' },
            '.board-reaction-wrap': {
              width: 'var(--board-detail-comment-width, 0px)',
            },
            '#board-reaction': {
              position: 'fixed',
              top: 'var(--board-detail-offset-top, var(--modal-padding, 0px))',
              bottom: 'calc(var(--extra-height, 0px))',
              overflowY: 'auto',
              width: 'var(--board-detail-comment-width, 0px)',
              borderTop: '1px solid #E7E9EC',
              '#board-reaction-tab-list': {
                padding: '17px 16px',
                borderBottom: '1px solid #E7E9EC',
                backgroundColor: 'white',
              },
              '.board-detail-comment': { paddingLeft: '8px' },
              '.board-detail-likes': { paddingLeft: '16px' },
              '#comment-input-wrap': {
                position: 'sticky',
                bottom: 0,
                width: 'calc(var(--board-detail-comment-width, 0px) - 8px)',
                zIndex: 1,
                padding: '0 0 24px 8px',
                backgroundColor: 'white',
              },
            },
            '&.isModal': {
              'header.isCompact': {
                paddingTop: 'var(--board-detail-header-padding-top, 16px)',
                '> h1': { paddingRight: '0px' },
              },
              '.board-reaction-wrap': { width: 0 },
            },
          },
          ...sx,
        }}
      >
        {/* iOS 에서 스크롤 시 Header 위, 옆 틈으로 게시글 내용이 보여서 가리기 위함 */}
        {/* 운영 배포를 위한 처리. 추후 게시글 코드 리팩토링 시 깨끗한 코드로 변경 필요 */}
        {isModal && isMobile && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              height: '56px',
              backgroundColor: 'white',
              zIndex: 2,
            }}
          />
        )}
        <BoardDetailHeader
          isMobile={isMobile}
          isModal={isModal}
          id={headerId}
          commentCount={commentCount}
          isCompact={isScrollDown}
          disabled={disabled}
          onClickComment={scrollToCommentSection}
          onDeleted={onDeleted}
          onEdited={onEdited}
          data={data}
        />
        <Stack
          className={'board-content-wrap'}
          direction={'column'}
          alignItems={'stretch'}
          divider={
            <Divider
              orientation={isRowDirection ? 'vertical' : 'horizontal'}
              flexItem
            />
          }
        >
          <Box component={'section'} id={'board-content'}>
            {data.content && <BoardDetailContent content={data.content} />}
            {data.config.viewType === BoardViewType.SLIDE && (
              <ImageSwiper
                imageList={data.files.slideImages.map((item) => item.link)}
                sx={{ margin: '56px 0 44px' }}
              />
            )}
            {data.files.attachments.length > 0 && (
              <FileAttachmentWrapper>
                {data.files.attachments.map((file, index) => (
                  <AttachmentFile
                    key={index}
                    file={file}
                    loading={isLoadingDownload}
                    disabled={disabled}
                    buttonOnClick={onDownloadAttachmentFile}
                    buttonIcon={<DownloadIcon />}
                    useLink={true}
                  />
                ))}
              </FileAttachmentWrapper>
            )}
            <BoardLikeButton
              isLike={isLike}
              disabled={disabled}
              onClick={toggleBoardLike}
              sx={{ display: 'flex', margin: '60px auto' }}
            />
          </Box>
          <Box
            ref={boardReactionWrapRef}
            component={'section'}
            className={'board-reaction-wrap'}
          >
            <Box id={'board-reaction'}>
              <TabContext value={selectedTab}>
                <BoardReactionTabList
                  id={'board-reaction-tab-list'}
                  onChange={(event, newValue) => setSelectedTab(newValue)}
                >
                  <Tab
                    label={`댓글 ${commentCount || ''}`}
                    value={ReactionTab.COMMENT}
                    disabled={disabled}
                  />
                  <Tab
                    label={`좋아요 ${data.feedback.likesCount || ''}`}
                    value={ReactionTab.LIKE}
                    disabled={disabled}
                  />
                </BoardReactionTabList>
                <TabPanel value={ReactionTab.COMMENT} sx={{ padding: 0 }}>
                  <BoardDetailComment
                    commentGroupId={data.commentGroupId}
                    isFixedInput={isRowDirection}
                    onChangeCount={setCommentCount}
                    handleComment={handleComment}
                    disabled={disabled}
                  />
                </TabPanel>
                <TabPanel value={ReactionTab.LIKE} sx={{ padding: 0 }}>
                  <BoardDetailLikes
                    ref={boardDetailLikesRef}
                    documentId={data.id}
                    disabled={disabled}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default BoardDetail;

const DownloadIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3006_18821)">
      <path
        d="M4 16.8994H16"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.001 12.8993V4.89941"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.001 12.8992L14 9.69922"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99902 12.8994L6 9.69946"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3006_18821">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.899414)"
        />
      </clipPath>
    </defs>
  </svg>
);
